import React, { useState } from 'react';

// Construct Component
const Stepper = ({ numSteps, onStep, urlOverride }) => {
  const [currentStep, setCurrentStep] = useState(onStep || 1);

  const stepStyle = (step) => ({
    height: '0px',
    borderBottom: step <= currentStep ? '5px solid #7A64EB' : '5px solid rgba(122, 100, 235, 0.2)',
    cursor: step <= currentStep ? 'pointer' : 'default',
    minWidth: '30px',
    borderRadius: '3px',
    margin: '0px 0px',
    zIndex: step == currentStep ? 1 : 0,
  });

  const handleClick = (step) => {
    if (step <= currentStep) {
      setCurrentStep(step);

      const currentUrl = window.location.href;
      let urlBase = currentUrl.substring(0, currentUrl.lastIndexOf('/'));

      if (urlOverride) {
        urlBase = currentUrl.substring(0, currentUrl.lastIndexOf('/app'));
        urlBase = urlOverride;
      }
      window.location.href = `${urlBase}/${step}`;
    }
  };

  return (
    <div className="FullWidthFlex Padded">
      {[...Array(numSteps)].map((_, index) => (
        <div
          key={index}
          className="OnePortionFlex step"
          style={stepStyle(index + 1)}
          onClick={() => handleClick(index + 1)}
        >{` `}</div>
      ))}
    </div>
  );
};

export default Stepper;
