function params(method, body) {
  let header = {};
  if (body) {
    header = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    };
    body = JSON.stringify(body);
    return { method: method, headers: header, body: body };
  } else {
    header = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    return { method: method, headers: header };
  }
}

const dictionaryService = {
  addDictionaryEntry: async function (type, value, field, scriptId) {
    try {
      const url = `${process.env.REACT_APP_API_URL}/dictionaries/script`;
      const body = {
        value: value,
        scriptId: scriptId,
        dictionary: type,
        field: field,
      };

      const newEntry = await fetch(url, params('POST', body));

      if (newEntry.ok) {
        const respond = await newEntry.json();
        return { label: value, value: respond.insertId };
      } else {
        console.error('Error adding dictionary entry');
        return null;
      }
    } catch (err) {
      console.error(err);
    }
  },

  addUserDictionaryEntry: async function (userId, dictionary, value, field) {
    try {
      const url = `${process.env.REACT_APP_API_URL}/dictionaries/user`;
      const body = {
        value: value,
        userId: userId,
        dictionary: dictionary,
        field: field,
      };

      const newEntry = await fetch(url, params('POST', body));

      if (newEntry.ok) {
        const respond = await newEntry.json();
        return { label: value, value: respond.insertId };
      } else {
        console.error('Error adding dictionary entry');
        return null;
      }
    } catch (err) {
      console.error(err);
    }
  },

  updateDictionary: async function (shotId, field, action, entry) {
    try {
      const url = `${process.env.REACT_APP_API_URL}/scripts/scriptDictionaryEntry`;
      const method = action === 'add' ? 'POST' : 'DELETE';
      const body = {
        shotId: shotId,
        multiSelect: field,
        action: action,
        entryValue: entry.value,
      };

      const update = await fetch(url, params(method, body));

      if (update.ok) {
        const respond = await update.json();
        return respond;
      } else {
        console.error('Error updating dictionary entry');
        return null;
      }
    } catch (err) {
      console.error(err);
    }
  },

  fetchDictionary: async function (type) {
    try {
      const url = `${process.env.REACT_APP_API_URL}/dictionaries/retrieve/dict_${type}`;
      const dictionary = await fetch(url, params('GET'));

      if (dictionary.ok) {
        const respond = await dictionary.json();
        return respond;
      } else {
        console.error('Error fetching dictionary entry');
        return [];
      }
    } catch (err) {
      console.error(err);
    }
  },

  fetchUserDictionary: async function (type, userId) {
    try {
      const url = `${process.env.REACT_APP_API_URL}/dictionaries/retrieve/user/user_dict_${type}/${userId}`;
      const dictionary = await fetch(url, params('GET'));

      if (dictionary.ok) {
        const respond = await dictionary.json();
        let mapped_values = respond
          ? respond.map((item) => ({ label: item.name, value: item.id }))
          : [];
        return mapped_values;
      } else {
        console.error('Error fetching user dictionary entry');
        return [];
      }
    } catch (err) {
      console.error(err);
    }
  },

  fetchScriptDictionary: async function (type, scriptId) {
    try {
      const url = `${process.env.REACT_APP_API_URL}/dictionaries/retrieve/script/script_dict_${type}/${scriptId}`;
      const dictionary = await fetch(url, params('GET'));

      if (dictionary.ok) {
        const respond = await dictionary.json();
        let mapped_values = respond
          ? respond.map((item) => ({ label: item.subject, value: item.id }))
          : [];
        return mapped_values;
      } else {
        console.error('Error fetching script dictionary entry');
        return [];
      }
    } catch (err) {
      console.error(err);
    }
  },

  formatForSelect: async function (refArray, valueField, labelField) {
    try {
      if (!refArray) return [];
      if (refArray[0] && refArray[0].value && refArray[0].label) return refArray;
      let mapped_values = refArray
        ? refArray.map((item) => ({ label: item[labelField], value: item[valueField] }))
        : [];
      return mapped_values;
    } catch (err) {
      console.error(err);
    }
  },
};

export default dictionaryService;
