// Import Libraries
import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

// Import Components
import RadioButton from '../../Elements/RadioButton.js';
import Icon from '../../Elements/Icon.js';

// Import Services
import subscriptionService from '../../../services/subscriptionService';
import SessionContext from '../../../context/Session';

const SelectPlan = ({ featureMsg, includeFree, nextStep, currentSubscription }) => {
  const [pricing, setPricing] = useState('2');
  const [includeTrial, setIncludeTrial] = useState(false);
  const [plans, setPlans] = useState([]);
  const [amountDue, setAmountDue] = useState('0.00');
  const [bestValue, setBestValue] = useState('0.00');
  const [selectedPlan, setSelectedPlan] = useState({});
  const { isMobile } = useContext(SessionContext);

  let dueDate = new Date();
  dueDate.setMonth(dueDate.getMonth() + 1);

  const changePricing = (hold, field, newPricing, plan) => {
    setPricing(newPricing);
    if (plan === undefined || plan === null || !plan) {
      plan = plans.find((plan) => plan.value === newPricing);
    }
    setAmountDue(plan.details['price']);
    setIncludeTrial(plan.details['include_trial']);
    setSelectedPlan(plan);
  };

  const onSubscribe = async () => {
    nextStep(2, selectedPlan);
    /* 
    let addSubscription = await subscriptionService.createSubscription(
      useUser.id,
      pricing,
      scriptId
    );
    if (addSubscription) {
      /*      if (useUser) {
        useUser.level = 'Pro';
      } else {
        useUser.level = 'Pro';
        user.level = 'Pro';
      }
      onRequestClose(true);
    }
    */
  };

  useEffect(() => {
    async function setup() {
      let getPlans = await subscriptionService.fetchAvailablePlans();
      if (includeFree) {
        getPlans.unshift({
          label: 'Free',
          value: '0',
          details: {
            frequency: 'Free',
            price: '0.00',
            user_level: '',
          },
        });
      }
      getPlans = getPlans.slice().sort((a, b) => {
        const priceA = parseFloat(a.details.price);
        const priceB = parseFloat(b.details.price);
        return priceB - priceA;
      });

      setPlans(getPlans);
      if (getPlans && getPlans.length > 0) {
        let yearlyPlan = getPlans.find((plan) => plan.details['frequency'] === 'Yearly');
        if (!yearlyPlan) {
          return;
        }
        changePricing(null, null, yearlyPlan.value, yearlyPlan);
        setSelectedPlan(yearlyPlan);
        let smallest =
          getPlans[getPlans.length - 1].label == 'Free'
            ? getPlans[getPlans.length - 2]
            : getPlans[getPlans.length - 1];
        let savings =
          parseFloat(yearlyPlan.details.price) - parseFloat(smallest.details.price * 12);
        savings = savings.toFixed(2);
        setBestValue(savings * -1);
      }
    }
    setup();
  }, []);

  return (
    <>
      <div className="modalHeader bold" style={{ minHeight: '30px' }}>
        <div style={{ marginRight: '2%', float: 'left', width: '10%' }}>
          <Icon
            icon="Camera"
            color="Gold"
            customClass="proImg"
            size="20"
            customStyle={{ marginTop: '-5px' }}
          />
        </div>
        <div style={{ float: 'left', width: '85%' }}>
          {includeFree ? 'Change your Plan' : 'Upgrade to use this pro feature'}
        </div>
      </div>

      <div className="modalContent" style={{ margin: isMobile ? '-10px' : '0px' }}>
        <div className="FullWidthFlex Padded" style={{ paddingBottom: '30px' }}>
          <span
            className="OnePortionFlex Padded MinSmall"
            style={{ borderRight: isMobile ? '' : '1px solid #cccccc' }}
          >
            {featureMsg ? (
              <div className="headline">
                {`${featureMsg}`}
                {isMobile ? null : (
                  <>
                    <br />
                    <br />
                  </>
                )}
              </div>
            ) : null}
            {plans &&
              plans.length > 0 &&
              plans.map((plan, index) => (
                <div key={index.toString()}>
                  <div className="FullWidthFlex Padded">
                    <span className="OnePortionFlex MinSmall">
                      <span className="bold">
                        <RadioButton
                          name="timing"
                          value={plan.value}
                          label={
                            plan.label +
                            ' ' +
                            (plan.details.user_level.charAt(0).toUpperCase() +
                              plan.details.user_level.slice(1))
                          }
                          selected={pricing === plan.value ? true : false}
                          saveUpdate={changePricing}
                        />
                      </span>
                      <div className="Padded" style={{ marginLeft: '10px' }}>
                        {`$${plan.details.price}`}
                        {plan.details['frequency'] === 'Yearly' && (
                          <span style={{ paddingLeft: '10px' }}>
                            {' '}
                            (
                            {(plan.details.price / 12).toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD',
                              minimumFractionDigits: 2,
                            })}
                            /month )
                          </span>
                        )}
                      </div>
                      {plan.details['include_trial'] ? (
                        <div className="Padded" style={{ marginLeft: '10px' }}>
                          {plan.details['include_trial'] ? 'Includes 1 month trial' : null}
                        </div>
                      ) : null}
                    </span>
                    {plan.details['staff_only'] ? (
                      <span
                        className="OnePortionFlex item bold"
                        style={{
                          fontSize: '14px',
                          backgroundColor: '#E35640',
                          color: 'White',
                          borderRadius: '10px',
                        }}
                      >
                        STAFF ONLY
                      </span>
                    ) : plan.details['frequency'] === 'Yearly' ? (
                      <span
                        className="OnePortionFlex item bold MinExSmall"
                        style={{
                          fontSize: '14px',
                          backgroundColor: '#E35640',
                          color: 'White',
                          borderRadius: '10px',
                          marginLeft: '10px',
                          alignContent: 'center',
                        }}
                      >
                        Best Value - Save ${bestValue}
                      </span>
                    ) : null}
                  </div>
                  <div
                    className="FullWidthFlex Padded"
                    style={{
                      display: 'block',
                      marginLeft: '15px',
                    }}
                  ></div>
                  <br />
                </div>
              ))}

            {isMobile ? null : (
              <>
                <br />
                <br />
              </>
            )}
            {includeTrial ? (
              <div className="FullWidthFlex Padded" style={{ paddingRight: '20px' }}>
                <span className="ThreePortionFlex">
                  Due on{' '}
                  {new Date(dueDate).toLocaleDateString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                  })}
                </span>
                <span className="ThreePortionFlex" style={{ textAlign: 'right' }}>
                  {parseFloat(amountDue).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
                </span>
              </div>
            ) : null}
          </span>
          <span className="OnePortionFlex Padded MinSmall">
            <div className="label headline">&nbsp;You get more with Pro</div>
            <div>
              <ul>
                <li>Generate shots for every scene</li>
                <li>Generate shots for the entire script in one click (coming soon)</li>
                <li>More Fields: Multi-Cam, etc.</li>
                <li>Flexible Export Options including CSV</li>
                <li>Upload Images and include them in PDF Exports</li>
              </ul>
            </div>
          </span>
        </div>

        <div className="FullWidthFlex Padded">
          {currentSubscription && currentSubscription.plan !== 'Free' ? (
            <button
              className="TwoPortionFlex linkText orangeText MinSmall"
              style={{ marginLeft: '30px', marginRight: '30px', marginTop: '20px' }}
              onClick={() => nextStep(3)}
            >
              Cancel my subscription
            </button>
          ) : (
            <span className="OnePortionFlex MinExSmall"></span>
          )}
          {isMobile ? null : <span className="OnePortionFlex"></span>}
          <button
            className="TwoPortionFlex buttonClone MinSmall"
            style={{ marginLeft: '30px', marginRight: '30px', marginTop: '20px' }}
            onClick={() => onSubscribe()}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default SelectPlan;
