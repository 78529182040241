// Import Libraries
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

// Construct Component
const SortOrder = ({ sort, field }) => {
  return (
    <span style={{ paddingLeft: '10px' }} className="purpleText large">
      {sort.field === field && sort.direction === 'DESC' && (
        <FontAwesomeIcon icon={faCaretDown} className="purpleText" />
      )}
      {sort.field === field && sort.direction === 'ASC' && (
        <FontAwesomeIcon icon={faCaretUp} className="purpleText" style={{ marginBottom: '-5px' }} />
      )}
    </span>
  );
};

export default SortOrder;
