import React from 'react';

function TermsOfUse() {
  return (
    <div className="containedPage">
      <iframe
        title="Embedded Webpage"
        src="termsofUse.html" // Replace with the URL of the webpage you want to embed
        width="100%"
        height="100%"
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default TermsOfUse;
