// Import Libraries
import React from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

// Setup Modal to Root
Modal.setAppElement('#root');

const IdleModal = ({ isOpen, onRequestClose }) => {
  return (
    <Modal isOpen={isOpen} contentLabel="Idle Modal" className="modal">
      <div className="close-button" role="button" onClick={() => onRequestClose(false)}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <div className="modalHeader bold">Are you still there?</div>

      <div className="modalContent">
        <p>
          You have been inactive for awhile.
          <br />
          <br />
          We want to keep your account secure so we're going to log you out if you don't click the
          button below within the next few minutes.{' '}
        </p>
        <div className="fullWidthFlex">
          <span className="OnePortionFlex"></span>
          <button className="OnePortionFlex buttonClone" onClick={() => onRequestClose(false)}>
            Keep me logged in!
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default IdleModal;
