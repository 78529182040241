import io from 'socket.io-client';

// Import Services
import ScriptService from './scriptService';
import ShotService from './shotService';
import Utils from './utilities';

let socket;

const updateScene = (setScript, processScene) => {
  setScript((existingScript) => {
    if (existingScript && existingScript.scenes && processScene) {
      const updatedScenes = existingScript.scenes.map((scene) => {
        if (scene.id === processScene.id) {
          return {
            ...scene,
            ai_status: processScene.ai_status,
            isGeneratingShots: processScene.isGeneratingShots,
            generation_error: processScene.generation_error,
            shot_list: processScene.shot_list,
            portions: processScene.portions,
          };
        }
        return scene;
      });
      return {
        ...existingScript,
        scenes: updatedScenes,
      };
    } else {
      console.error('Script or script.scenes is undefined.');
      return existingScript;
    }
  });
};

const addScene = (setScript, newScene) => {
  setScript((prevScript) => {
    const foundScene = prevScript.scenes.find((scene) => scene.id === newScene.id);
    if (foundScene) {
      return prevScript;
    }

    newScene.shot_list = Utils.buildTempShot(prevScript, newScene);
    const updatedScenes = [...prevScript.scenes, newScene].sort(
      (a, b) => a.scene_position - b.scene_position
    );

    return { ...prevScript, scenes: updatedScenes };
  });
};

const updateSceneManual = (setScript, sceneId, field, newValue) => {
  setScript((prevScript) => {
    const updatedScenes = prevScript.scenes.map((scene) => {
      if (scene.id === sceneId) {
        return {
          ...scene,
          [field]: newValue,
        };
      }
      return scene;
    });
    return { ...prevScript, scenes: updatedScenes };
  });
};

const findSceneIndexByShotId = (scenes, shotId) => {
  for (let i = 0; i < scenes.length; i++) {
    const scene = scenes[i];
    const foundShot = scene.shot_list.find((shot) => shot.id == shotId);
    if (foundShot) {
      return i;
    }
  }
  return -1;
};
const addShot = (script, setScript, sceneId, newShot) => {
  const foundScene = script.scenes.find((scene) => scene.id === sceneId);
  if (!foundScene) return;
  const updatedShotList = [...foundScene.shot_list, newShot].sort(
    (a, b) => a.shot_number - b.shot_number
  );
  const updatedScenes = script.scenes.map((scene) =>
    scene.id === sceneId ? { ...scene, shot_list: updatedShotList } : scene
  );
  setScript({ ...script, scenes: updatedScenes });
};

const updateShot = async (script, setScript, shotId, field, newValue) => {
  try {
    const sceneIndex = findSceneIndexByShotId(script.scenes, shotId);
    if (sceneIndex === -1) return false;

    const updatedScene = { ...script.scenes[sceneIndex] };
    const shotIndex = updatedScene.shot_list.findIndex((s) => s.id == shotId);
    if (shotIndex === -1) return false;

    if (field === 'full') {
      updatedScene.shot_list[shotIndex] = newValue;
    } else if (field === 'shot_number') {
      updatedScene.shot_list[shotIndex] = {
        ...updatedScene.shot_list[shotIndex],
        shot_number: newValue,
      };
      updatedScene.shot_list.sort((a, b) => a.shot_number - b.shot_number);
    } else if (field === 'deleted_at') {
      updatedScene.shot_list.splice(shotIndex, 1);
    } else {
      if (field === 'FX' || field === 'VFX') {
        newValue = newValue ? 1 : 0;
      } else if (field === 'image') {
        if (!newValue || newValue.length < 1) {
          newValue = null;
        } else {
          newValue = await ShotService.retrieveImg(shotId);
        }
      }
      updatedScene.shot_list[shotIndex] = {
        ...updatedScene.shot_list[shotIndex],
        [field]: newValue,
      };
    }

    setScript((prevScript) => {
      const updatedScenes = [...prevScript.scenes];
      updatedScenes[sceneIndex] = updatedScene;
      return { ...prevScript, scenes: updatedScenes };
    });

    return true;
  } catch (error) {
    console.error('Error updating shot:', error);
    return false;
  }
};

const ProjectSockets = {
  connectSocket: function (
    projectId,
    userId,
    setLockedFields,
    setView,
    setActiveCollaborators,
    scriptRef,
    setScript,
    setNewUpdate
  ) {
    if (!socket || !socket.connected) {
      let baseUrl = process.env.REACT_APP_API_URL.replace('/api', '');
      socket = io.connect(baseUrl, { transports: ['websocket'] });

      socket.on('connect', () => {
        socket.emit('openProject', projectId, userId);

        // User connection events
        ProjectSockets.onEvent('userJoinedProject', (user, userList) => {
          setActiveCollaborators(userList);
        });

        ProjectSockets.onEvent('userLeftProject', (user, userList) => {
          setActiveCollaborators(userList);
        });

        // Locking and unlocking fields
        ProjectSockets.onEvent('unlockField', (record, recordId, field, userId) => {
          const fieldId = `${record}-${recordId}-${field}`;
          setLockedFields((prevFields) => {
            const updatedFields = new Map(prevFields);
            updatedFields.delete(fieldId);
            return updatedFields;
          });
        });

        ProjectSockets.onEvent('lockField', (record, recordId, field, userId) => {
          const fieldId = `${record}-${recordId}-${field}`;
          setLockedFields((prevFields) => {
            const updatedFields = new Map(prevFields);
            updatedFields.set(fieldId, { user: userId, time: new Date() });
            return updatedFields;
          });
        });

        ProjectSockets.onEvent('pageProcessed', (pageNumber) => {
          setScript((prevScript) => ({
            ...prevScript,
            last_processed_page: pageNumber,
          }));
        });

        ProjectSockets.onEvent('updateScript', (scriptId, field, value) => {
          setScript((prevScript) => ({
            ...prevScript,
            [field]: value,
          }));
        });

        ProjectSockets.onEvent('addScene', (scene) => {
          addScene(setScript, scene);
        });

        ProjectSockets.onEvent('updateScene', (scene) => {
          setView((prevView) => ({
            ...prevView,
            updateDictionary: {
              ...prevView.updateDictionary,
              ['subjects']: true,
            },
          }));
          updateScene(setScript, scene);
        });

        ProjectSockets.onEvent('updateSceneManual', (sceneDetail) => {
          updateSceneManual(setScript, sceneDetail.sceneId, sceneDetail.field, sceneDetail.value);
        });

        ProjectSockets.onEvent('addShot', (sceneId, newShot) => {
          let script = scriptRef.current;
          addShot(script, setScript, sceneId, newShot);
        });

        ProjectSockets.onEvent('updateShot', (shotId, field, newValue, editor) => {
          let script = scriptRef.current;
          if (editor === userId) {
            return;
          }
          updateShot(script, setScript, shotId, field, newValue);
        });

        ProjectSockets.onEvent('updateDictionary', (dictionary) => {
          setView((prevView) => ({
            ...prevView,
            updateDictionary: {
              ...prevView.updateDictionary,
              [dictionary]: true,
            },
          }));
        });
      });

      // Error handling
      socket.on('connect_error', (error) => {
        console.error('WebSocket connection error:', error);
      });
      socket.on('disconnect', () => {
        console.log('WebSocket connection closed');
      });
      socket.on('error', (error) => {
        console.error('WebSocket error:', error);
      });
    }
  },

  emitLockField: function (type, typeId, field, setMyLockedField) {
    if (socket) {
      const fieldId = `${type}-${typeId}-${field}`;
      setMyLockedField(fieldId);
      socket.emit('lockProjectField', type, typeId, field);
    } else {
      console.log('Error: Lost server connection.');
    }
  },

  emitUnlockField: function (type, typeId, field, setMyLockedField) {
    if (socket) {
      setMyLockedField(null);
      socket.emit('unlockProjectField', type, typeId, field);
    } else {
      console.log('Error: Lost server connection.');
    }
  },

  onEvent: function (eventName, callback) {
    if (socket) {
      if (callback) {
        socket.on(eventName, callback);
      } else {
        socket.off(eventName);
      }
    } else {
      console.log('Error: Lost server connection.');
    }
  },

  disconnectSocket: function () {
    if (socket) {
      socket.emit('userDisconnecting');
      socket.disconnect();
    }
  },
};

export default ProjectSockets;
