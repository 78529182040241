// Import Libraries
import React, { createContext, useEffect, useState, useMemo } from 'react';

// Create a context
const ProjectViewContext = createContext();

// Create a context provider component
export const ProjectViewProvider = ({ children }) => {
  const [view, setView] = useState({
    pdfActive: true,
    viewColumns: 2,
    isLoading: true,
    sceneIndex: 0,
    expandShots: false,
    uploadedFile: null,
    manualEntry: false,
    updateDictionary: {
      focal: false,
      frame: false,
      focus: false,
      camera: false,
      movement: false,
      types: false,
      subjects: false,
    },
    includeImages: false,
    sceneImagesAllowed: false,
    onScreen: 'scene',
  });
  const [lockedFields, setLockedFields] = useState(new Map());
  const [activeCollaborators, setActiveCollaborators] = useState([]);
  const [myLockedField, setMyLockedField] = useState(null);
  const [newUpdate, setNewUpdate] = useState(false);
  const [lockField, setLockField] = useState(() => () => {});
  const [unlockField, setUnlockField] = useState(() => () => {});

  useEffect(() => {
    // Add Code here to set up base locked fields
  }, []);

  // Memoize the context value
  const contextValue = useMemo(
    () => ({
      view,
      setView,
      lockedFields,
      setLockedFields,
      myLockedField,
      setMyLockedField,
      activeCollaborators,
      setActiveCollaborators,
      newUpdate,
      setNewUpdate,
      lockField,
      setLockField,
      unlockField,
      setUnlockField,
    }),
    [view, lockedFields, myLockedField, activeCollaborators, newUpdate, lockField, unlockField]
  );

  return <ProjectViewContext.Provider value={contextValue}>{children}</ProjectViewContext.Provider>;
};

export default ProjectViewContext;
