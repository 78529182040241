// Import Libraries
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

// Construct Component
const LoginButton = ({ useClass }) => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate('/login');
  };

  return (
    <a className={`roundButton ${useClass}`} onClick={() => handleLogin()}>
      Sign Up or Log In
    </a>
  );
};

export default LoginButton;
