// Import Libraries
import React, { useState, useEffect, useContext } from 'react';

// Import Components
import CollaboratorLine from './collaboratorLine';
import Icon from '../../Elements/Icon';

// Import Services
import scriptService from '../../../services/scriptService';

// Import Context
import SessionContext from '../../../context/Session';

// Construct Component
const CollaborationDropdown = ({ script, updateScript, setShow }) => {
  const [count, setCount] = useState(0);
  const [collaboratorEmail, setCollaboratorEmail] = useState('');
  const [role, setRole] = useState('viewer');
  const [addCollabError, setAddCollabError] = useState('');
  const { isMobile, generalModalDetails, setGeneralModalDetails } = useContext(SessionContext);

  const inviteNewCollaborator = async () => {
    if (!collaboratorEmail) {
      setAddCollabError('Please enter an email address');
      return;
    }

    let updatedCollabList = await scriptService.addCollaborator(script.id, collaboratorEmail, role);
    script.collaborators = updatedCollabList;
    updateScript(script);

    setGeneralModalDetails({
      open: true,
      header: <span>Your collaborator has been added.</span>,
      message: (
        <div className="Padded">
          Your collaborator {collaboratorEmail} now has {role} access. Please ask them to check for
          email from support@shotkraft.com
          <br />
          <br />
          <div className="fullWidthContainer Padded">
            <span className="OneHalfFlex" />
            <button
              className="OneHalfFlex buttonClone"
              onClick={() =>
                setGeneralModalDetails({
                  open: false,
                  header: null,
                  message: null,
                })
              }
            >
              Ok
            </button>
          </div>
        </div>
      ),
    });
  };

  const updateEmail = (email) => {
    setCollaboratorEmail(email);
    setAddCollabError('');
  };

  const setCollabCount = (script) => {
    if (!script.collaborators) {
      return 1;
    }
    let tempCount = 1;
    tempCount += script.collaborators.editors.length;
    tempCount += script.collaborators.viewers.length;
    setCount(tempCount);
  };

  useEffect(() => {
    setCollabCount(script);
  }, [script]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
        background: '#fff',
        borderRadius: '4px',
        border: '1px solid #ccc',
        position: 'absolute',
        top: '0',
        right: '0',
        zIndex: '100',
        maxWidth: '500px',
        width: 'calc(100% - 20px)',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
      }}
    >
      <div className="fullWidthContainer">
        <span className="TenPortionFlex" style={{ textAlign: 'left' }}>
          Share {script.script_name} Project with
        </span>
        <span className="OnePortionFlex" style={{ textAlign: 'right' }}>
          <Icon
            icon="Close"
            color="Black"
            onClick={() => setShow(false)}
            style={{ cursor: 'pointer' }}
          />
        </span>
      </div>
      <div className="fullWidthContainer">
        <div
          className="TenPortionFlex"
          style={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #ccc',
            padding: '5px',
            borderRadius: '4px',
            marginTop: '10px',
          }}
        >
          <input
            type="text"
            value={collaboratorEmail}
            onChange={(e) => updateEmail(e.target.value)}
            placeholder="Enter email"
            style={{
              flex: 1,
              padding: '5px 0px 5px 5px',
              border: 'none',
              outline: 'none',
              margin: '0 5px',
            }}
          />
          <select
            value={role}
            onChange={(e) => setRole(e.target.value)}
            style={{
              padding: '5px 5px 5px 0px',
              border: 'none',
              outline: 'none',
              cursor: 'pointer',
              fontSize: '14px',
              fontFamily: 'Lato',
            }}
          >
            <option value="viewer">Viewer</option>
            <option value="editor">Editor</option>
          </select>
        </div>
        <button
          className="OnePortionFlex buttonClone"
          style={{ marginTop: '10px' }}
          onClick={() => inviteNewCollaborator()}
        >
          Invite
        </button>
      </div>
      {addCollabError ? <div className="fullWidthContainer errorText">{addCollabError}</div> : null}
      <div className="fullWidthContainer">
        <div
          className="fullWidthContainer Padded"
          style={{ marginTop: '10px', borderTop: '1px solid #0E0F11' }}
        >
          {count} collaborators in this file
        </div>
        <div className="fullWidthContainer Padded">
          <CollaboratorLine
            script={script}
            updateScript={updateScript}
            collaborator={script.collaborators.owner}
            level={'owner'}
          />
          {script.collaborators && script.collaborators.editors.length ? (
            <>
              {script.collaborators.editors.map((collab) => (
                <CollaboratorLine
                  key={collab.id}
                  script={script}
                  updateScript={updateScript}
                  collaborator={collab}
                  level={'editor'}
                />
              ))}
            </>
          ) : null}
          {script.collaborators && script.collaborators.viewers.length ? (
            <>
              {script.collaborators.viewers.map((collab) => (
                <CollaboratorLine
                  key={collab.id}
                  script={script}
                  updateScript={updateScript}
                  collaborator={collab}
                  level={'viewer'}
                />
              ))}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CollaborationDropdown;
