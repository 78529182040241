// Import Libraries
import React, { useContext } from 'react';

// Import Components
import GeneralModal from '../../components/Modals/GeneralModal.js';

// Setup Application Contexts
import SessionContext from '../../context/Session';

// Construct Component
function NewUserWelcomeFooter() {
  const { generalModalDetails, setGeneralModalDetails } = useContext(SessionContext);

  const openPrivacyPolicy = async () => {
    let policy = await fetch('../privacyPolicy.html');
    if (policy.ok) {
      let policyText = (await policy.text()).toString();
      setGeneralModalDetails({
        open: true,
        header: '',
        message: <div dangerouslySetInnerHTML={{ __html: policyText }} />,
        wide: true,
      });
    }
  };

  const openTerms = async () => {
    let terms = await fetch('../termsofUse.html');
    if (terms.ok) {
      let termsText = (await terms.text()).toString();
      setGeneralModalDetails({
        open: true,
        header: '',
        message: <div dangerouslySetInnerHTML={{ __html: termsText }} />,
        wide: true,
      });
    }
  };

  return (
    <div>
      <div>
        By selecting Next, I agree to ShotKraft's
        <a
          className="linkText"
          style={{ padding: '0px 5px' }}
          target="_blank"
          href="/privacyPolicy"
        >
          Privacy Policy
        </a>{' '}
        and
        <a className="linkText" style={{ padding: '0px 5px' }} target="_blank" href="/termsOfUse">
          Terms of Use
        </a>
      </div>
    </div>
  );
}

export default NewUserWelcomeFooter;
