// Import Libraries
import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FullStory } from '@fullstory/browser';
import { isMobile } from 'react-device-detect';

const LogUserIn = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    FullStory('trackEvent', {
      name: 'LoginOrSignup',
    });

    const isSystemBrowser =
      window.navigator.standalone || window.matchMedia('(display-mode: standalone)').matches;

    console.log('LOGIN');
    if (isMobile && !isSystemBrowser) {
      window.open('/login', '_system');
      loginWithRedirect();
    } else {
      loginWithRedirect();
    }
  }, []);

  return <div></div>;
};

export default LogUserIn;
