import React, { useState, useEffect } from 'react';

function RadioButton({ label, name, value, selected, saveUpdate, isDisabled }) {
  const handleInputChange = () => {
    if (!isDisabled) {
      let field = name;
      if (name.indexOf('-')) {
        field = name.substring(0, name.indexOf('-'));
      }
      saveUpdate(null, field, value);
    }
  };

  const changed = () => {
    // intentionally left blank
  };

  return (
    <span className={`OnePortionFlex`} onClick={() => handleInputChange()}>
      <input
        type="radio"
        disabled={isDisabled ? true : false}
        name={name}
        value={value}
        checked={selected}
        style={{ width: 'initial' }}
        onChange={() => changed()}
      />
      <label style={{ width: 'initial' }}>{label}</label>
    </span>
  );
}

export default RadioButton;
