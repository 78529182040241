// Import Libraries
import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

// Import Components
import ExportProjectButton from './Project/exportProjectButton';
import CollaboratorDropdown from './Project/collaboratorDropdown.js';
import CollaboratorBubbleList from './Project/collaboratorBubbleList.js';
import Icon from '../Elements/Icon';

// Context
import ScriptContext from '../../context/Script';
import SessionContext from '../../context/Session';
import ProjectViewContext from '../../context/ProjectView';

// Import Services
import utilities from '../../services/utilities';

// Construct Component
const ShotTableHeader = () => {
  const { script, setScript } = useContext(ScriptContext);
  const { isMobile } = useContext(SessionContext);
  const { view, setView, activeCollaborators } = useContext(ProjectViewContext);
  const [showCollabDropdown, setShowCollabDropdown] = useState(false);

  function changePdfView() {
    setView({ ...view, pdfActive: !view.pdfActive });
  }

  const updateScript = async (updatedScript) => {
    setScript({ ...script, ...updatedScript });
  };

  useEffect(() => {
    if (isMobile) {
      setView({ ...view, viewColumns: 1 });
    }
  }, [isMobile]);

  return (
    <div className="fullWidthContainer shotHeaderBar">
      <span className={`${isMobile ? 'TenPortionFlex' : 'FivePortionFlex'} MinSmall`}>
        <div className="fullWidthContainer">
          {isMobile && view.onScreen === 'shot' ? (
            <span className="shotHeader" onClick={() => setView({ ...view, onScreen: 'scene' })}>
              <FontAwesomeIcon
                icon={faChevronLeft}
                className="linkText"
                style={{ paddingRight: '15px', paddingLeft: '0px' }}
              />{' '}
              BACK TO SCENES
            </span>
          ) : (
            <h1 className="shotHeader ThreePortionFlex MinSmall">
              {script ? script.script_name : ''}
            </h1>
          )}

          <span className="OnePortionFlex MinSmall">
            <div className="Padded" style={{ paddingLeft: '20px' }}>
              <CollaboratorBubbleList
                collaborators={script.collaborators}
                setShowCollabDropdown={setShowCollabDropdown}
                level={''}
                activeList={activeCollaborators}
              />
              {script.level && script.level == 'owner' && (
                <button
                  className="buttonGray"
                  onClick={() => setShowCollabDropdown(!showCollabDropdown)}
                >
                  Share
                </button>
              )}
            </div>
          </span>
        </div>

        {script.level === 'owner' && (
          <span style={{ position: 'relative', display: 'block' }}>
            {showCollabDropdown && (
              <CollaboratorDropdown
                script={script}
                updateScript={updateScript}
                setShow={setShowCollabDropdown}
              />
            )}
          </span>
        )}
      </span>
      <div className={`ThreePortionFlex MinSmall ${view.isLoading ? 'hidden' : ''}`}>
        {script && script.page_count !== script.last_processed_page ? (
          <span className="progress-bar-text">
            <span>Still Processing:</span>
            <span className="progress-bar-container">
              <span
                className="progress-bar"
                style={{
                  width: `${
                    script && script.page_count !== script.last_processed_page
                      ? ((script.last_processed_page / script.page_count) * 100).toFixed(2)
                      : 0
                  }%`,
                }}
              ></span>
            </span>
          </span>
        ) : (
          <>
            <div
              className="FullWidthFlex"
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {isMobile ? (
                <> </>
              ) : (
                <span className="ThreePortionFlex">
                  <span
                    className={`pageIcon ${view.pdfActive == true ? 'pageIconSelected' : ''}`}
                    onClick={() => changePdfView()}
                    style={{ display: 'none' }}
                  >
                    <FontAwesomeIcon icon={faFilePdf} style={{ padding: '5px 5px 5px 4px' }} />
                  </span>

                  <span
                    className={`pageIcon ${view.viewColumns == 1 ? 'pageIconSelected' : ''}`}
                    onClick={() => setView({ ...view, viewColumns: 1 })}
                  >
                    <Icon icon="List" size={16} color="Black" />
                    List
                  </span>
                  <span
                    className={`pageIcon ${view.viewColumns == 2 ? 'pageIconSelected' : ''}`}
                    onClick={() => setView({ ...view, viewColumns: 2 })}
                  >
                    <Icon icon="Card" size={16} color="Black" />
                    Cards
                  </span>
                </span>
              )}
              <span className="TwoPortionFlex" style={{ marginRight: '15px' }}>
                <ExportProjectButton />
              </span>
            </div>
            <div
              className="FullWidthFlex"
              style={{
                position: 'relative',
                display: 'block',
              }}
            ></div>
          </>
        )}
      </div>
    </div>
  );
};

export default ShotTableHeader;
