// Import Libraries
import React, { useState, useContext } from 'react';

// Import Components
import CustomSelect from '../../Forms/CustomSelect.js';
import RadioButton from '../../Elements/RadioButton.js';
import Checkbox from '../../Elements/Checkbox.js';
import ProFlag from '../../Elements/ProFlag.js';

// Services
import dictionaryService from '../../../services/dictionaryService.js';

// Context
import ScriptContext from '../../../context/Script';
import ProjectViewContext from '../../../context/ProjectView';

function ExpandedForm({ scene, shot, dictionaries, handleShotFieldChange, addNewUserDictionary }) {
  const { script } = useContext(ScriptContext);
  const [tempNotes, setTempNotes] = useState({});
  const { lockedFields, lockField, unlockField } = useContext(ProjectViewContext);

  const isReadOnly = script.level === 'viewer';
  const handleUpdateNotes = async (sceneNumber, shotId) => {
    if (tempNotes[shotId] === undefined) return;
    handleShotFieldChange(shotId, 'notes', tempNotes[shotId]);
    setTempNotes((prev) => ({
      ...prev,
      [shotId]: undefined,
    }));
    return;
  };

  return (
    <div
      className={`Padded ${shot.expanded ? '' : 'hidden'}`}
      style={{ borderTop: '1px dashed #7A64EB' }}
    >
      <div className="fullWidthContainer">
        <div className="OnePortionFlex Padded MinSmall">
          <div className="createableFocusLabelFieldVisible">
            <span className="createableFocusLabel visibleLabel">
              <span className="createableFocusLabelText">Notes</span>
            </span>
            <textarea
              className={`inputWithLabel ${
                isReadOnly || lockedFields.has(`shot-${shot.id}-notes`) ? 'readOnly' : ''
              }`}
              style={{ flex: '3 1', borderRadius: '0px 8px 8px 0px' }}
              value={tempNotes[shot.id] !== undefined ? tempNotes[shot.id] : shot.notes || ''}
              onChange={(e) =>
                setTempNotes((prev) => ({
                  ...prev,
                  [shot.id]: e.target.value,
                }))
              }
              onBlur={() => {
                handleUpdateNotes(scene.id, shot.id);
              }}
              id={`shot-${shot.id}-notes`}
              readOnly={isReadOnly || lockedFields.has(`shot-${shot.id}-notes`)}
            />
          </div>
        </div>
      </div>

      <span className="fullWidthContainer">
        <span className="OnePortionFlex">
          <div className="fullWidthContainer">
            <div className="OnePortionFlex Padded MinSmall">
              <span>
                <CustomSelect
                  optionList={dictionaries['movement']}
                  selected={shot.movement}
                  baseItem={shot}
                  selectedFieldName={'movement'}
                  changeHandler={handleShotFieldChange}
                  addOption={script.level === 'owner' ? addNewUserDictionary : null}
                  label={'Movement'}
                  multi={true}
                  id={`shot-${shot.id}-movement`}
                  readOnly={isReadOnly || lockedFields.has(`shot-${shot.id}-movement`)}
                  lockField={lockField}
                  unlockField={unlockField}
                />
              </span>
            </div>
            <div className="OnePortionFlex Padded MinThird MinSmall">
              <span>
                <CustomSelect
                  optionList={dictionaries['camera']}
                  selected={shot.camera}
                  baseItem={shot}
                  selectedFieldName={'camera'}
                  changeHandler={handleShotFieldChange}
                  addOption={script.level === 'owner' ? addNewUserDictionary : null}
                  label={'Camera'}
                  multi={true}
                  id={`shot-${shot.id}-camera`}
                  readOnly={isReadOnly || lockedFields.has(`shot-${shot.id}-camera`)}
                  lockField={lockField}
                  unlockField={unlockField}
                />
              </span>
            </div>
          </div>
        </span>
        <span className="OnePortionFlex">
          <div className="fullWidthContainer">
            <div className="OnePortionFlex Padded MinSmall">
              <span>
                <CustomSelect
                  optionList={dictionaries['focus']}
                  selected={shot.focus}
                  baseItem={shot}
                  selectedFieldName={'focus'}
                  changeHandler={handleShotFieldChange}
                  addOption={script.level === 'owner' ? addNewUserDictionary : null}
                  label={'Focus'}
                  multi={true}
                  id={`shot-${shot.id}-focus`}
                  readOnly={isReadOnly || lockedFields.has(`shot-${shot.id}-focus`)}
                  lockField={lockField}
                  unlockField={unlockField}
                />
              </span>
            </div>
            <div className="OnePortionFlex Padded MinSmall">
              <span>
                <CustomSelect
                  optionList={dictionaries['frame']}
                  selected={shot.frame}
                  baseItem={shot}
                  selectedFieldName={'frame'}
                  changeHandler={handleShotFieldChange}
                  addOption={script.level === 'owner' ? addNewUserDictionary : null}
                  label={'Frame'}
                  multi={true}
                  id={`shot-${shot.id}-frame`}
                  readOnly={isReadOnly || lockedFields.has(`shot-${shot.id}-frame`)}
                  lockField={lockField}
                  unlockField={unlockField}
                />
              </span>
            </div>
          </div>
        </span>
      </span>

      <span className="fullWidthContainer">
        <span className="OnePortionFlex">
          <div className="fullWidthContainer">
            <div className="OnePortionFlex Padded MinSmall">
              <span>
                <CustomSelect
                  optionList={dictionaries['focal']}
                  selected={shot.focal}
                  baseItem={shot}
                  selectedFieldName={'focal'}
                  changeHandler={handleShotFieldChange}
                  addOption={script.level === 'owner' ? addNewUserDictionary : null}
                  label={'Focal'}
                  multi={true}
                  id={`shot-${shot.id}-focal`}
                  readOnly={isReadOnly || lockedFields.has(`shot-${shot.id}-focal`)}
                  lockField={lockField}
                  unlockField={unlockField}
                />
              </span>
            </div>
            <div className="OnePortionFlex Padded MinSmall"></div>
          </div>
        </span>
        <span className="OnePortionFlex"></span>
      </span>

      <span className="fullWidthContainer" style={{ padding: '5px' }}>
        <span className="OnePortionFlex Padded">
          <div className="fullWidthContainer popoverHover">
            <span
              className="OnePortionFlex label"
              style={{ paddingRight: '0px', minWidth: '80px', maxWidth: '80px' }}
            >
              <ProFlag
                type="pro"
                scriptLevel={script ? script.features : 'Basic'}
                scriptId={script.id}
              />
              Camera
            </span>
            <span className="TwoPortionFlex Padded">
              <div className="fullWidthContainer MinExSmall" style={{ alignItems: 'flex-end' }}>
                <span className="OnePortionFlex Padded" style={{ minWidth: '25px' }}>
                  <RadioButton
                    name={`selected_camera-${shot.id}`}
                    value="A"
                    label="A"
                    selected={shot.selected_camera === 'A' ? true : false}
                    saveUpdate={handleShotFieldChange}
                    isDisabled={
                      !isReadOnly && script.features && script.features.toLowerCase() !== 'basic'
                        ? false
                        : true
                    }
                  />
                </span>
                <span className="OnePortionFlex Padded" style={{ minWidth: '25px' }}>
                  <RadioButton
                    name={`selected_camera-${shot.id}`}
                    value="B"
                    label="B"
                    selected={shot.selected_camera === 'B' ? true : false}
                    saveUpdate={handleShotFieldChange}
                    isDisabled={
                      !isReadOnly && script.features && script.features.toLowerCase() !== 'basic'
                        ? false
                        : true
                    }
                  />
                </span>
                <span className="OnePortionFlex Padded" style={{ minWidth: '25px' }}>
                  <RadioButton
                    name={`selected_camera-${shot.id}`}
                    value="C"
                    label="C"
                    selected={shot.selected_camera === 'C' ? true : false}
                    saveUpdate={handleShotFieldChange}
                    isDisabled={
                      !isReadOnly && script.features && script.features.toLowerCase() !== 'basic'
                        ? false
                        : true
                    }
                  />
                </span>
                <span className="OnePortionFlex Padded" style={{ minWidth: '26px' }}>
                  <RadioButton
                    name={`selected_camera-${shot.id}`}
                    value="D"
                    label="D"
                    selected={shot.selected_camera === 'D' ? true : false}
                    saveUpdate={handleShotFieldChange}
                    isDisabled={
                      !isReadOnly && script.features && script.features.toLowerCase() !== 'basic'
                        ? false
                        : true
                    }
                  />
                </span>
              </div>
            </span>
          </div>
        </span>
        <span className="OnePortionFlex Padded">
          <div className="fullWidthContainer Padded">
            <div className="OnePortionFlex"></div>
            <div className="OnePortionFlex" style={{ marginLeft: '20px', minWidth: '45px' }}>
              <Checkbox
                name="FX"
                label="FX"
                selected={shot.FX}
                saveUpdate={handleShotFieldChange}
                spanClass="floatRight"
                readOnly={isReadOnly}
              />
            </div>
            <div className="OnePortionFlex" style={{ marginLeft: '20px', minWidth: '51px' }}>
              <Checkbox
                name="VFX"
                label="VFX"
                selected={shot.VFX}
                saveUpdate={handleShotFieldChange}
                spanClass="floatRight"
                readOnly={isReadOnly}
              />
            </div>
          </div>
        </span>
      </span>
    </div>
  );
}

export default ExpandedForm;
