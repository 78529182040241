// Import Libraries
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faCalendar } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';

// Import Services
import AdminUtils from '../../services/utils';
import AdminSubSvc from '../../services/adminSubscriptionService';

import 'react-datepicker/dist/react-datepicker.css';

// Construct Component
function AddCreditsModal({ processUser, sub, isModalOpen, setModalIsOpen }) {
  const [credits, setCredits] = useState(0);
  const [useBy, setUseBy] = useState(new Date());
  const [message, setMessage] = useState('');

  const addCredits = async () => {
    const useByDate = new Date(useBy);
    useByDate.setHours(0, 0, 0, 0);
    if (useByDate && credits) {
      let added = await AdminSubSvc.addCredits(sub, credits, useByDate, processUser);
    } else {
      setMessage('Please enter a valid date and number of credits to add.');
    }

    window.location.reload();
    setModalIsOpen(false);
  };

  useEffect(() => {
    const now = new Date();
    const useByDate = new Date(now.setMonth(now.getMonth() + 1));
    setUseBy(useByDate);
  }, [sub]);

  return (
    <div>
      <Modal isOpen={isModalOpen} onRequestClose={() => setModalIsOpen(sub)} className="modal">
        <button className="close-button" onClick={() => setModalIsOpen(sub)}>
          <FontAwesomeIcon icon={faClose} />
        </button>
        <div className="modalHeader">
          <div className="bold">Add credits for User: {processUser.id}</div>
        </div>
        <div className="modalContent">
          <div className="fullWidthContainer Padded">
            {processUser.first_name} {processUser.last_name} ({processUser.email}) currently has{' '}
            {processUser && processUser.available_credits
              ? processUser.available_credits.totalCredits
              : 0}{' '}
            credits available
          </div>
          <div className="fullWidthContainer Padded">
            You can add additional credits to this user's account here. To remove credits, use the -
            icon beside the active credits.
          </div>
          <div className="fullWidthContainer Padded">
            <span className="Padded TwoPortionFlex">Add Credits: </span>
            <span className="Padded ThreePortionFlex">
              <input value={credits} onChange={(e) => setCredits(e.target.value)} />
            </span>
            <span className="Padded TwoPortionFlex"></span>
          </div>
          <div className="fullWidthContainer Padded">
            <span className="Padded TwoPortionFlex">To use by:</span>
            <span className="Padded FivePortionFlex">
              <DatePicker selected={useBy} onChange={(value) => setUseBy(value)} />
            </span>
          </div>

          <div className="fullWidthContainer Padded">
            <span className="OnePortionFlex"></span>
            <button className="OnePortionFlex buttonClone" onClick={() => addCredits()}>
              Save
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AddCreditsModal;
