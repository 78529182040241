import React, { useEffect, useState } from 'react';

//Services
import UserService from '../services/userService';

const DownloadSample = () => {
  const [downloadedFile, setDownloadedFile] = useState(false);

  const handleDownload = () => {
    UserService.addActionLog('Download Sample Script');

    const filePath = '/Sample_Script.pdf';
    const downloadLink = document.createElement('a');
    downloadLink.href = process.env.PUBLIC_URL + filePath;
    downloadLink.download = 'Sample_Script.pdf';

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className="containedPage">
      <button
        style={{ margin: '20px', float: 'right' }}
        className="buttonClone"
        onClick={handleDownload}
      >
        Download Script
      </button>
      <iframe
        title="Embedded Webpage"
        src="Sample_Script.pdf"
        width="100%"
        height="100%"
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default DownloadSample;
