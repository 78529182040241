// Import Libraries
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

// Import Components
import Select from '../../components/Elements/Select';
import Sorter from '../components/sortOrder';
import SearchElement from '../components/searchElement';
import UserInfo from '../components/user/userInfo';

// Import Services
import AdminUserSvc from '../services/adminUserService';
import AdminUtils from '../services/utils';

function AdminUserPage() {
  const [users, setUsers] = useState([]);
  const [displaySearch, setDisplaySearch] = useState(false);
  const [openUserId, setOpenUserId] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [criteria, setCriteria] = useState({
    limit: 25,
    sort: { field: 'last_login', direction: 'DESC' },
    search: {},
    offset: 0,
  });

  function updateCriteria(type, value) {
    let newCriteria = { ...criteria };
    newCriteria[type] = value;
    setCriteria(newCriteria);
  }

  const getUserList = async () => {
    let userList = await AdminUserSvc.getAllUsers(criteria);
    setUsers(userList.users);
    setTotalRows(userList.totalCount);
  };

  const updateSort = (field) => {
    let newCriteria = { ...criteria };
    if (field === criteria.sort.field && criteria.sort.direction === 'DESC') {
      newCriteria['sort'] = { field: field, direction: 'ASC' };
    } else {
      newCriteria['sort'] = { field: field, direction: 'DESC' };
    }
    setCriteria(newCriteria);
  };

  const onSearch = (field, value, type) => {
    let newCriteria = { ...criteria };
    if (!newCriteria.search[field]) {
      newCriteria.search[field] = { value: '', type: 'LIKE' };
    }
    if (value) {
      newCriteria.search[field].value = value;
    } else if (value !== null) {
      if (
        newCriteria.search[field].type.includes('>') ||
        newCriteria.search[field].type.includes('<')
      ) {
        newCriteria.search[field].value = 0;
      } else {
        newCriteria.search[field].value = '';
      }
    }
    if (type) {
      newCriteria.search[field].type = type;
      if (type === 'Any') {
        delete newCriteria.search[field];
      } else if (!value && (type.includes('>') || type.includes('<'))) {
        newCriteria.search[field].value = 0;
      }
    }
    setCriteria(newCriteria);
  };

  function createPageArray(pageCount) {
    const result = [];
    for (let i = 1; i <= pageCount; i++) {
      result.push(i);
    }
    return result;
  }

  useEffect(() => {
    getUserList();
  }, [criteria]);

  return (
    <div style={{ marginLeft: '20px', marginRight: '20px' }}>
      <div
        className="FullWidthFlex"
        style={{ paddingTop: '15px', paddingBottom: '15px', marginRight: '30px' }}
      >
        <span className="FourPortionFlex large">Admin User Page</span>

        <span className="OnePortionFlex bold">{`Found ${totalRows} users`}</span>
        <span className="OnePortionFlex"></span>

        <span className="TwoPortionFlex FullWidthFlex">
          <Select
            selectLabel={`Page`}
            labelStyle={`static`}
            selectOptions={createPageArray(Math.ceil(totalRows / criteria.limit))}
            selectedValue={Math.floor(criteria.offset / criteria.limit) + 1}
            handleSelectChange={(value) => updateCriteria('offset', (value - 1) * criteria.limit)}
            className="TwoPortionFlex"
          />
          <span
            className="OnePortionFlex"
            style={{ paddingLeft: '10px', paddingTop: '15px' }}
          >{` of ${Math.ceil(totalRows / criteria.limit)}`}</span>
        </span>
        <span className="TwoPortionFlex">
          <Select
            selectLabel={`Rows per page`}
            labelStyle={`static`}
            selectOptions={[10, 25, 50, 100, 300]}
            selectedValue={criteria.limit}
            handleSelectChange={(value) => updateCriteria('limit', value)}
          />
        </span>
        <span className="OnePortionFlex"></span>
      </div>

      {users && (
        <div className="FullWidthContainer staff">
          <div className={`card flatLeftCard TenPortionFlex`} style={{ marginRight: '30px' }}>
            <div className="cardHeader fullWidthFlex bold" style={{ cursor: 'pointer' }}>
              <span className="OnePortionFlex" onClick={() => updateSort('id')}>
                ID
                <Sorter sort={criteria.sort} field={`id`} />
              </span>
              <span className="TwoPortionFlex" onClick={() => updateSort(`first_name`)}>
                First Name <Sorter sort={criteria.sort} field={`first_name`} />
              </span>
              <span className="TwoPortionFlex" onClick={() => updateSort(`last_name`)}>
                Last Name <Sorter sort={criteria.sort} field={`last_name`} />
              </span>
              <span className="ThreePortionFlex" onClick={() => updateSort(`email`)}>
                Email <Sorter sort={criteria.sort} field={`email`} />
              </span>
              <span className="TwoPortionFlex" onClick={() => updateSort(`created_at`)}>
                Created At <Sorter sort={criteria.sort} field={`created_at`} />
              </span>
              <span className="TwoPortionFlex" onClick={() => updateSort(`last_login`)}>
                Last Login <Sorter sort={criteria.sort} field={`last_login`} />
              </span>
              <span className="OnePortionFlex" onClick={() => updateSort(`script_count`)}>
                Scripts <Sorter sort={criteria.sort} field={`script_count`} />
              </span>
              <span className="OnePortionFlex purpleText large">
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  onClick={() => setDisplaySearch(!displaySearch)}
                />
              </span>
            </div>
            {displaySearch && (
              <div className="FullWidthFlex" style={{ paddingBottom: '10px' }}>
                <span className="OnePortionFlex fullWidthContainer top">
                  <SearchElement
                    searchOptions={['Any', '=', '>', '<', 'LIKE']}
                    fieldName={`id`}
                    search={criteria.search['id']}
                    onSearch={onSearch}
                  />
                </span>
                <span className="TwoPortionFlex fullWidthContainer top">
                  <SearchElement
                    searchOptions={['Any', '=', 'LIKE']}
                    fieldName={`first_name`}
                    search={criteria.search['first_name']}
                    onSearch={onSearch}
                  />
                </span>
                <span className="TwoPortionFlex fullWidthContainer top">
                  <SearchElement
                    searchOptions={['Any', '=', 'LIKE']}
                    fieldName={`last_name`}
                    search={criteria.search['last_name']}
                    onSearch={onSearch}
                  />
                </span>
                <span className="ThreePortionFlex fullWidthContainer top">
                  <SearchElement
                    searchOptions={['Any', '=', 'LIKE']}
                    fieldName={`email`}
                    search={criteria.search['email']}
                    onSearch={onSearch}
                  />
                </span>
                <span className="TwoPortionFlex fullWidthContainer top"></span>
                <span className="TwoPortionFlex fullWidthContainer top"></span>
                <span className="OnePortionFlex fullWidthContainer top">
                  <SearchElement
                    searchOptions={['Any', '=', '>', '<', 'LIKE']}
                    fieldName={`script_count`}
                    search={criteria.search['script_count']}
                    onSearch={onSearch}
                  />
                </span>
                <span className="OnePortionFlex fullWidthContainer top"></span>
              </div>
            )}
          </div>

          <div className="hiddenScroll" style={{ height: 'calc(100vh - 285px)' }}>
            {users.map((processUser) => (
              <div
                key={processUser.id}
                className={`card flatLeftCard TenPortionFlex ${
                  openUserId === processUser.id ? 'selectedCard' : null
                }`}
              >
                <div className="cardHeader">
                  <span className="OnePortionFlex">{processUser.id}</span>
                  <span className="TwoPortionFlex">{processUser.first_name}</span>
                  <span className="TwoPortionFlex">{processUser.last_name}</span>
                  <span className="ThreePortionFlex">{processUser.email}</span>
                  <span className="TwoPortionFlex">
                    {processUser.created_at && processUser.created_at
                      ? AdminUtils.FormatDateWithTime(processUser.created_at)
                      : ''}
                  </span>
                  <span className="TwoPortionFlex">
                    {processUser.last_login && processUser.last_login
                      ? AdminUtils.FormatDateWithTime(processUser.last_login)
                      : ''}
                  </span>
                  <span className="OnePortionFlex">{processUser.script_count}</span>
                  <span className="OnePortionFlex">
                    {openUserId !== processUser.id ? (
                      <FontAwesomeIcon
                        className="linkText"
                        icon={faEye}
                        onClick={() => setOpenUserId(processUser.id)}
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="linkText orangeText"
                        icon={faEyeSlash}
                        onClick={() => setOpenUserId(null)}
                      />
                    )}
                  </span>
                </div>

                {openUserId === processUser.id && <UserInfo processUser={processUser} />}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminUserPage;
