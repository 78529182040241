// Import Libraries
import React, { useState } from 'react';

// Services
import AdminUtils from '../../services/utils';

const UserBox = ({ activeUser }) => {
  const [error, setError] = useState(null);

  return (
    <>
      {activeUser ? (
        <div className="card" style={{ minWidth: '300px' }}>
          <div style={{ padding: '10px' }}>
            <div className="cardTitle fullWidthContainer">
              <span className="OnePortionFlex">
                User: {activeUser.first_name} {activeUser.last_name}
              </span>
            </div>
            <div className="FullWidthFlex">
              <span className="OnePortionFlex Padded">Email </span>
              <span className="TwoPortionFlex Padded">{activeUser.email}</span>
            </div>
            <div className="FullWidthFlex">
              <span className="OnePortionFlex Padded">Script Count</span>
              <span className="TwoPortionFlex Padded">{activeUser.script_count}</span>
            </div>
            <div className="FullWidthFlex">
              <span className="OnePortionFlex Padded">Created On </span>
              <span className="TwoPortionFlex Padded">
                {activeUser.created_at ? AdminUtils.FormatDateWithTime(activeUser.created_at) : ''}
              </span>
            </div>

            <div className="FullWidthFlex">
              <span className="OnePortionFlex Padded">Last Login</span>
              <span className="TwoPortionFlex Padded">
                {activeUser.last_login ? AdminUtils.FormatDateWithTime(activeUser.last_login) : ''}
              </span>
            </div>
            <div className="fullWidthContainer">
              <p className="errorText">{error}</p>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default UserBox;
