// Import Libraries
import React, { useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronCircleUp,
  faChevronCircleDown,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import io from 'socket.io-client';

// Import Components
import ShotTable from './shotTable';
import PdfViewer from '../../components/Fragments/PDFviewer.js';
import ProFlag from '../../components/Elements/ProFlag.js';
import SceneGenerationSection from '../../components/Fragments/Project/sceneGenerateSection.js';

// Import Services
import ScriptService from '../../services/scriptService.js';
import Utils from '../../services/utilities.js';

// Context
import ScriptContext from '../../context/Script.js';
import SessionContext from '../../context/Session';
import ProjectViewContext from '../../context/ProjectView';

// Construct Component
const SceneTable = () => {
  const { script, setScript, updateScriptScene } = useContext(ScriptContext);
  const { isMobile } = useContext(SessionContext);
  const { view, setView, lockedFields, setLockedFields } = useContext(ProjectViewContext);

  const isReadOnly = script.level === 'viewer';

  function changePdfView() {
    setView({ ...view, pdfActive: !view.pdfActive });
  }

  const expandScene = (sceneNumber) => {
    let index =
      sceneNumber === null
        ? null
        : script.scenes.findIndex((scene) => scene.id === sceneNumber) || 0;

    if (view.sceneIndex === index && !isMobile) {
      return;
    }

    if (index || isMobile) {
      let scene = script.scenes[index];
      updateScene(scene.id);
      let sceneImagesAllowed = true;

      if (script.features && script.features.toLowerCase() === 'basic' && scene.ai_status < 1) {
        sceneImagesAllowed = false;
      }

      let onScreen = 'scene';
      if (isMobile) {
        onScreen = 'shot';
      }

      setView((prevView) => {
        return {
          ...prevView,
          sceneIndex: index,
          sceneImagesAllowed: sceneImagesAllowed,
          onScreen: onScreen,
        };
      });
    } else {
      setView((prevView) => {
        return {
          ...prevView,
          sceneIndex: index,
        };
      });
    }
  };

  const updateScene = async (sceneId, field, newValue) => {
    const updatedScenes = script.scenes.map((scene) => {
      if (scene.id === sceneId) {
        let updatedScene = {
          ...scene,
          shot_list: Utils.buildTempShot(script, { ...scene, [field]: newValue }),
        };
        if (field) {
          updatedScene[field] = newValue;
        }
        return updatedScene;
      }
      return scene;
    });

    updateScriptScene(updatedScenes);
    if (!field && !newValue) return;
    try {
      await ScriptService.changeSceneChange(sceneId, field, newValue, script.id);
    } catch (error) {
      console.error('Error updating scene:', error);
    }
  };

  const updateSceneText = (sceneId, field, value) => {
    setScript((prevScript) => {
      const updatedScenes = prevScript.scenes.map((scene) => {
        if (scene.id === sceneId) {
          return {
            ...scene,
            [field]: value,
          };
        }
        return scene;
      });
      return { ...prevScript, scenes: updatedScenes };
    });
  };

  const addScene = async () => {
    try {
      const position = script.scenes.length + 1;
      const newScene = await ScriptService.createManualScene(script.id, position);
    } catch (error) {
      console.error('Error adding scene:', error);
    }
  };

  const deleteScene = async (sceneId) => {
    try {
      const deleteResponse = await ScriptService.deleteScene(sceneId);
      if (deleteResponse) {
        const deletedSceneIndex = script.scenes.findIndex((scene) => scene.id === sceneId);
        const newSceneIndex = deletedSceneIndex > 0 ? deletedSceneIndex - 1 : 0;

        updateScriptScene(script.scenes.filter((scene) => scene.id !== sceneId));

        setView((prevView) => ({ ...prevView, sceneIndex: newSceneIndex }));
      }
    } catch (error) {
      console.error('Error deleting scene:', error);
    }
  };

  useEffect(() => {
    if (view.sceneIndex === null) return;
    if (view.sceneIndex === script.scenes.length - 1) {
      const container = document.getElementById('scrollSceneTable');
      if (container) {
        container.scrollTop = container.scrollHeight + 200;
      }
    }
  }, [view.sceneIndex]);

  useEffect(() => {
    updateScene(script.scenes[0].id);
    if (!isMobile) {
      expandScene(script.scenes[0].id);
    }
  }, []);

  return (
    <div
      className={`${isMobile ? 'shotTable' : 'ShotList hiddenScroll limitHeightScene'}`}
      style={{
        backgroundColor: '#F9FAFC',
      }}
    >
      <span
        className="fullWidthContainer"
        style={{ paddingLeft: '30px', width: 'calc(50vw - 80px)' }}
      >
        <h3 className="ThreePortionFlex">{`Scenes`}</h3>
        {view.manualEntry || !view.uploadedFile ? (
          <button
            id="manualEntry"
            className={`buttonCloneLight OnePortionFlex`}
            style={{ float: 'right', margin: '0px', minWidth: '110px' }}
            onClick={() => addScene()}
          >
            + Add Scene
          </button>
        ) : null}
      </span>
      {script.scenes && script.scenes.length === 0 ? (
        <p>No scenes available. Please ensure your script has scene numbers.</p>
      ) : (
        <div id="scrollSceneTable" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
          {script.scenes.map((scene) => (
            <div
              key={scene.id}
              className={`card ${
                script.scenes[view.sceneIndex]?.id === scene.id &&
                !isMobile &&
                view.viewColumns == 2
                  ? 'selected'
                  : ''
              }`}
              onClick={() => {
                if (view.viewColumns > 1) {
                  expandScene(scene.id);
                }
              }}
            >
              <div
                className={`cardHeader ${scene.shot_list ? 'hasShots' : ''}`}
                style={{ minHeight: '40px' }}
              >
                <div className="fullWidthContainer bold">
                  <span className="OnePortionFlex cardTop left">
                    {view.manualEntry ? (
                      <input
                        type="text"
                        onChange={(e) => updateScene(scene.id, 'scene_number', e.target.value)}
                        value={scene.scene_number}
                        placeholder="#"
                        id={`scene-${scene.id}-scene_number`}
                        className={`${
                          isReadOnly || lockedFields.has(`scene-${scene.id}-scene_number`)
                            ? 'readOnly'
                            : ''
                        }`}
                        readOnly={isReadOnly || lockedFields.has(`scene-${scene.id}-scene_number`)}
                      />
                    ) : (
                      <span>{scene.scene_number}</span>
                    )}
                  </span>
                  <span className="TwoPortionFlex cardTop">
                    {scene.portions && scene.portions.wholes > 0 && <>{scene.portions.wholes} </>}
                    {scene.portions && scene.portions.remainder > 0 && (
                      <>
                        <span className="numerator">{`${scene.portions.remainder}`}</span>/
                        <span className="denominator">8</span>
                      </>
                    )}
                  </span>
                  <span className="TenPortionFlex">
                    {view.manualEntry ? (
                      <textarea
                        value={scene.header_text}
                        onChange={(e) => updateSceneText(scene.id, 'header_text', e.target.value)}
                        onBlur={(e) => updateScene(scene.id, 'header_text', e.target.value)}
                        placeholder="Enter Scene Header or Slug Line"
                        id={`scene-${scene.id}-header_text`}
                        className={`${
                          isReadOnly || lockedFields.has(`scene-${scene.id}-header_text`)
                            ? 'readOnly'
                            : ''
                        }`}
                        readOnly={isReadOnly || lockedFields.has(`scene-${scene.id}-header_text`)}
                      />
                    ) : (
                      scene.header_text
                    )}
                  </span>
                  <span
                    className={`${isMobile ? 'fullWidthContainer' : 'FivePortionFlex'}`}
                    style={{
                      display: 'flex',
                      ...(view.viewColumns == 2 ? { minWidth: '150px', marginRight: '-10px' } : {}),
                    }}
                  >
                    {isMobile ? (
                      <>
                        <span className="ThreePortionFlex">&nbsp;</span>
                        <span className="EightPortionFlex">
                          <SceneGenerationSection scene={scene} view={view} setView={setView} />
                        </span>
                      </>
                    ) : (
                      <SceneGenerationSection scene={scene} view={view} setView={setView} />
                    )}

                    <div className={`OnePortionFlex Padded`} style={{ float: 'right' }}>
                      {isMobile ? (
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          className={`sizeUp linkText`}
                          onClick={() => {
                            expandScene(scene.id);
                          }}
                          style={{ float: 'right', marginTop: '5px', paddingRight: '5px' }}
                        />
                      ) : view.viewColumns == 1 ? (
                        <>
                          {scene.shot_list && (
                            <FontAwesomeIcon
                              icon={
                                script.scenes[view.sceneIndex]?.id === scene.id
                                  ? faChevronCircleUp
                                  : faChevronCircleDown
                              }
                              style={{ float: 'right' }}
                              className={`linkText sizeUp`}
                              onClick={() =>
                                expandScene(
                                  script.scenes[view.sceneIndex]?.id === scene.id ? null : scene.id
                                )
                              }
                            />
                          )}
                        </>
                      ) : null}
                    </div>
                  </span>
                </div>
              </div>

              {(view.viewColumns == 2 || isMobile) &&
                script.scenes[view.sceneIndex]?.id == scene.id &&
                view.uploadedFile && (
                  <span
                    className={`OnePortionFlex Padded MinSmall ${
                      view.pdfActive && script.scenes[view.sceneIndex].id === scene.id
                        ? ''
                        : 'hidden'
                    }`}
                  >
                    <PdfViewer
                      uploadedFile={view.uploadedFile}
                      styleDetail={`${script.scenes[view.sceneIndex] ? '' : 'pdfContainer1'}`}
                      startPage={scene.page_start}
                      endPage={scene.page_end}
                    />
                  </span>
                )}

              {!view.uploadedFile || view.manualEntry ? (
                <div className={`FullWidthFlex Padded`} onClick={() => changePdfView()}>
                  <textarea
                    rows={5}
                    onChange={(e) => {
                      updateSceneText(scene.id, 'scene_text', e.target.value);
                      e.target.style.height = 'auto';
                      const maxHeight = 200;
                      if (e.target.scrollHeight <= maxHeight) {
                        e.target.style.height = e.target.scrollHeight + 'px';
                      } else {
                        e.target.style.height = maxHeight + 'px';
                      }
                    }}
                    onBlur={(e) => {
                      updateScene(scene.id, 'scene_text', e.target.value);
                      e.target.style.height = 'auto';
                      const maxHeight = 200;
                      if (e.target.scrollHeight <= maxHeight) {
                        e.target.style.height = e.target.scrollHeight + 'px';
                      } else {
                        e.target.style.height = maxHeight + 'px';
                      }
                    }}
                    value={scene.scene_text}
                    placeholder="Enter Scene Text"
                    id={`scene-${scene.id}-scene_text`}
                    className={`${
                      isReadOnly || lockedFields.has(`scene-${scene.id}-scene_text`)
                        ? 'readOnly'
                        : ''
                    }`}
                    readOnly={isReadOnly || lockedFields.has(`scene-${scene.id}-scene_text`)}
                  />
                </div>
              ) : null}

              {view.viewColumns === 1 &&
              script.scenes[view.sceneIndex]?.id === scene.id &&
              !isMobile ? (
                <div
                  className={`${script.scenes[view.sceneIndex].id === scene.id ? '' : 'hidden'}`}
                >
                  <ShotTable view={view} setView={setView} />
                </div>
              ) : null}

              {view.manualEntry ? (
                <div
                  className={`linkText orangeText FullWidthFlex Padded`}
                  onClick={() => deleteScene(scene.id)}
                  style={{ flexDirection: 'row-reverse' }}
                >
                  <FontAwesomeIcon style={{ padding: '1px' }} icon={faTrashCan} />
                </div>
              ) : (
                <div></div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SceneTable;
