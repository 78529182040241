// Import Libraries
import React, { useState, useEffect } from 'react';

// Import Services
import utilities from '../../../services/utilities';

// Components
import Icon from '../../Elements/Icon.js';

// Construct Component
const CollaboratorBubbleList = ({ collaborators, setShowCollabDropdown, level, activeList }) => {
  const [collabCount, setCollabCount] = useState(0);
  const [editorCount, setEditorCount] = useState(0);
  const [viewerCount, setViewerCount] = useState(0);
  const [hoveredUser, setHoveredUser] = useState(null); // Track hovered user for popover

  // useEffect to count collaborators
  useEffect(() => {
    setCollabCount(collaborators.editors.length + collaborators.viewers.length + 1);
    setEditorCount(collaborators.editors.length);
    setViewerCount(collaborators.viewers.length);
  }, [collaborators]);

  // Helper function to determine background and text color based on activeList
  const getStyles = (collab) => {
    const isActive = activeList ? activeList.includes(collab.user_id) : true;
    return {
      backgroundColor: isActive ? collab.color || '#fff' : '#fff',
      color: isActive ? (utilities.isDarkColor(collab.color) ? 'white' : 'black') : 'black',
      cursor: 'pointer',
    };
  };

  return (
    <>
      {collaborators && (
        <span className="FivePortionFlex Padded" style={{ marginTop: '5px' }}>
          &nbsp;
          {collaborators.owner && (
            <span
              className="circleIcon userCircle popoverParent"
              style={{
                ...getStyles(collaborators.owner),
                position: 'relative',
                zIndex: collabCount,
              }}
              onMouseEnter={() => setHoveredUser(collaborators.owner.email)} // Show tooltip
              onMouseLeave={() => setHoveredUser(null)} // Hide tooltip
            >
              {collaborators.owner?.initials}
              {hoveredUser === collaborators.owner.email && (
                <div className="popover" style={{ color: 'black' }}>
                  {collaborators.owner.email}
                </div>
              )}
            </span>
          )}
          &nbsp;
          {level === 'owner' && (
            <Icon
              icon="Add"
              color="Black"
              onClick={() => setShowCollabDropdown(true)}
              customStyle={{
                float: 'right',
                paddingLeft: '30px',
                marginTop: '-5px',
              }}
              size="35"
            />
          )}
          {collaborators.editors &&
            collaborators.editors.map((collab, editIndex) => (
              <span
                key={collab.id}
                className="circleIcon userCircle popoverParent"
                style={{
                  marginLeft: '-7px',
                  ...getStyles(collab),
                  position: 'relative',
                  zIndex: viewerCount + editIndex,
                }}
                onMouseEnter={() => setHoveredUser(collab.email)} // Show tooltip
                onMouseLeave={() => setHoveredUser(null)} // Hide tooltip
              >
                {collab.initials}
                {hoveredUser === collab.email && (
                  <div className="popover" style={{ color: 'black' }}>
                    {collab.email}
                  </div>
                )}
              </span>
            ))}
          &nbsp;
          {collaborators.viewers.map((collab, viewIndex) => (
            <span
              key={collab.id}
              className="circleIcon userCircle popoverParent"
              style={{
                marginLeft: '-7px',
                ...getStyles(collab),
                position: 'relative',
                zIndex: viewIndex,
              }}
              onMouseEnter={() => setHoveredUser(collab.email)} // Show tooltip
              onMouseLeave={() => setHoveredUser(null)} // Hide tooltip
            >
              <span>{collab.initials}</span>
              {hoveredUser === collab.email && (
                <div className="popover" style={{ color: 'black' }}>
                  {collab.email}
                </div>
              )}
            </span>
          ))}
        </span>
      )}
    </>
  );
};

export default CollaboratorBubbleList;
