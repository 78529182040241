import React, { useEffect, useState, useContext } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
//import { gtag } from 'ga-gtag';

// Import Components
import LoadingSpinner from '../../Elements/LoadingSpinner';

// Import Services
import paymentService from '../../../services/paymentService';
import subService from '../../../services/subscriptionService';

// Context
import { UserContext } from '../../../context/User';
import { ConsoleView } from 'react-device-detect';

const StripeNewCard = ({
  plan,
  hasCards,
  setNewCard,
  clientSecret,
  scriptId,
  invoice,
  nextStep,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if ((!plan || !plan.details || !plan.details.ext_product) && !invoice) {
      return;
    }
    stripe.retrieveSetupIntent(clientSecret).then(({ paymentIntent }) => {
      // Handle result.error or result.setupIntent
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);

    try {
      const stripeResponse = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: window.location.href,
        },
        redirect: 'if_required',
      });

      if (stripeResponse && stripeResponse.setupIntent) {
        setMessage('Payment method saved');
        let pay = stripeResponse.setupIntent.payment_method;
        let submitted = false;

        if (plan) {
          let product = plan.details.ext_product;
          try {
            if (product && product.price && product.price.id) {
              let price = (product?.price?.unit_amount || 0) / 100;
              /*gtag('event', 'purchase', { method: 'Google' });
              gtag.event('purchase', {
                value: price,
                currency: 'USD',
                items: [
                  {
                    item_name: 'Subscription',
                    item_id: product.price.id,
                    quantity: 1,
                  },
                ],
              });*/
              /*
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: 'purchase',
                event_category: 'Subscription',
                event_label: product.price.id,
                value: product.price.unit_amount / 100,
              });
              */
            } else {
              console.error('No product or price id found in plan');
            }
          } catch {
            console.error('Error in gtag');
          }
          submitted = await subService.createSubscription(user.id, product, pay, scriptId);
        } else if (invoice) {
          submitted = await paymentService.payInvoice(invoice.id, pay);
        }

        if (!submitted) {
          setMessage('An unexpected error occurred saving your payment information.');
        } else if (nextStep) {
          nextStep(4);
        } else {
          window.location.reload();
        }
      } else if (stripeResponse && stripeResponse.error) {
        if (
          stripeResponse.error.type === 'card_error' ||
          stripeResponse.error.type === 'validation_error'
        ) {
          setMessage(stripeResponse.error.message);
        } else {
          setMessage('An unexpected error occurred.');
        }
      } else {
        setMessage('Unable to confirm payment method. Please try again.');
      }

      setMessage('');
      return;
    } catch (error) {
      console.error('Error confirming setup:', error.message);
      setMessage('An unexpected error occurred.');
    } finally {
      setIsLoading(false);
    }
  };

  const paymentElementOptions = {
    layout: 'tabs',
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <div className="fullWidthContainer" style={{ paddingTop: '40px' }}>
        {hasCards ? (
          <button className="TwoPortionFlex buttonCloneLight" onClick={() => setNewCard(false)}>
            Use Existing Card
          </button>
        ) : (
          <span className="TwoPortionFlex"></span>
        )}
        <span className="OnePortionFlex"></span>
        <button
          className="TwoPortionFlex buttonClone"
          disabled={isLoading || !stripe || !elements}
          id="submit"
        >
          <span id="button-text">{isLoading ? <LoadingSpinner /> : 'Pay now'}</span>
        </button>
      </div>
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
};

export default StripeNewCard;
