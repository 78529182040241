// Import Libraries
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

// Import Services
import AdminUserSvc from '../../services/adminUserService';

// Construct Component
const UserHistory = ({ processUser }) => {
  const [displayHistory, setDisplayHistory] = useState(false);
  const [userHistory, setUserHistory] = useState([]);

  const getUserHistory = async () => {
    let userHistory = await AdminUserSvc.getUserHistory(processUser.id);
    setUserHistory(userHistory);
  };

  useEffect(() => {
    getUserHistory();
  }, [processUser]);

  return (
    <div style={{ padding: '10px' }}>
      {/*}
      <div className="fullWidthFlex">
        <span className="TenPortionFlex large">User History</span>
        <span
          className="OnePortionFlex purpleLink"
          onClick={() => setDisplayHistory(!displayHistory)}
        >
          {displayHistory && <FontAwesomeIcon icon={faCaretUp} />}
          {!displayHistory && <FontAwesomeIcon icon={faCaretDown} />}
        </span>
      </div>
  */}

      {/*displayHistory && (*/}
      <div className={`card flatLeftCard TenPortionFlex`} style={{ marginRight: '30px' }}>
        <div className="cardHeader fullWidthFlex bold">
          <span className="TwoPortionFlex">Date/Time</span>
          <span className="FourPortionFlex">Action</span>
          <span className="TwoPortionFlex">Reference</span>
          <span className="TwoPortionFlex">Lag Time</span>
          <span className="ThreePortionFlex">Notes</span>
          <span className="ThreePortionFlex">Performed By</span>
        </div>

        <div className="hiddenScroll" style={{ height: '400px' }}>
          {userHistory.map((history) => (
            <div key={history.id} className={`card flatLeftCard TenPortionFlex`}>
              <div className="cardHeader">
                <span className="TwoPortionFlex">{history.timestamp}</span>
                <span className="FourPortionFlex">{history.action}</span>
                <span className="TwoPortionFlex">
                  {history.reference_type} {history.reference_id}
                </span>
                <span className="TwoPortionFlex">{history.lag_time}</span>
                <span className="ThreePortionFlex">{history.notes}</span>
                <span className="ThreePortionFlex">{history.performed_by}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/*})}*/}
    </div>
  );
};

export default UserHistory;
