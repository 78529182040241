import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

function PdfViewer({ uploadedFile, styleDetail, startPage, endPage }) {
   const [numPages, setNumPages] = useState(null);
   const [actualEndPage, setActualEndPage] = useState(null);
   //   options={{ verbosity: pdfjs.VerbosityLevel.ERRORS }}

   const onDocumentLoadSuccess = ({ numPages }) => {
      if (endPage) {
         setActualEndPage(endPage);
      } else {
         setActualEndPage(numPages);
      }
      setNumPages(numPages);
   };

   return (
      <div className={`pdfContainer ${styleDetail}`}>
         <Document file={uploadedFile} onLoadSuccess={onDocumentLoadSuccess}>
            {numPages !== null && actualEndPage !== null ? (
               <div className="pdfWrapper">
                  {Array.from(new Array(actualEndPage), (el, index) => (
                     <div key={`page_${index + 1}`}>
                        {index + 1 >= startPage && index + 1 <= actualEndPage ? (
                           <Page pageNumber={index + 1} renderTextLayer={false} renderAnnotationLayer={false} />
                        ) : null}
                     </div>
                  ))}
               </div>
            ) : null}
         </Document>
      </div>
   );
}

export default PdfViewer;
