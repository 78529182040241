// Libraries
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { useAuth0 } from '@auth0/auth0-react';
import { FullStory } from '@fullstory/browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faClose, faBars } from '@fortawesome/free-solid-svg-icons';

// Context
import { UserContext } from '../context/User';
import SessionContext from '../context/Session';

// Components
import Login from './UserComponents/login.js';
import Logout from './UserComponents/logout.js';
import AdminMenu from '../admin/components/AdminHeader.js';
import Icon from './Elements/Icon.js';

//Services
import UserService from '../services/userService';

Modal.setAppElement('#root');

// Construct Component
const Header = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [isMainDropdownOpen, setIsMainDropdownOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { isAuthenticated, isLoading } = useAuth0();
  const { isMobile, generalModalDetails, setGeneralModalDetails } = useContext(SessionContext);
  const [blockNav, setBlockNav] = useState(false);
  const site = window.location.origin;

  useEffect(() => {
    let page = window.location.pathname;
    if (site.indexOf('demo') === -1 && site.indexOf('localhost') === -1) {
      if (page === '/') {
        window.location.replace(`https://www.shotkraft.com${page}`);
      }
      if (page === '/contactUs' || page === '/faq') {
        window.open(`${site}${page}`, '_blank');
      }
    }

    if (page.indexOf('/newUserWelcome') > 0) {
      setBlockNav(true);
    }
  }, [isLoading]);

  const clickLogo = () => {
    if (user && user.id) {
      window.location.replace(`${site}/app/home`);
    } else {
      window.location.replace(site);
    }
  };

  const handleBlockNav = () => {
    if (blockNav) {
      setGeneralModalDetails({
        open: true,
        header: <span>Please complete all required fields.</span>,
        message: (
          <div className="Padded">
            We need to collect just a little bit more information.
            <br />
            <br />
            <div className="fullWidthContainer Padded">
              <span className="OneHalfFlex" />
              <button
                className="OneHalfFlex buttonClone"
                onClick={() =>
                  setGeneralModalDetails({
                    open: false,
                    header: null,
                    message: null,
                  })
                }
              >
                Ok
              </button>
            </div>
          </div>
        ),
      });
    }
  };

  const handleScriptUpload = () => {
    UserService.addActionLog('New Project ', null, null, 'From Header');
    FullStory('trackEvent', {
      name: 'InitiateNewScriptHeader',
    });

    window.location.replace(`${site}/app/newProject/1`);
  };

  const handleClickEvent = (originalOnClick) => (event) => {
    if (blockNav) {
      event.preventDefault(); // Prevent default behavior if blockNav is true
      handleBlockNav();
    } else if (originalOnClick) {
      originalOnClick(event);
    }
  };

  return (
    <>
      <header className="FullWidth header">
        <img
          src="/Logo2White.png"
          style={{ cursor: 'pointer' }}
          className="logo"
          alt="logo"
          onClick={handleClickEvent(clickLogo)}
        />
        <span style={{ flex: 'auto' }}></span>

        {isAuthenticated ? (
          <FontAwesomeIcon
            icon={faBars}
            className="header_nav_icon barIcon"
            onClick={handleClickEvent(() => setIsMainDropdownOpen(!isMainDropdownOpen))}
          />
        ) : (
          <Login useClass="headerItem signupButton" />
        )}
        <nav className={`header_navigation ${isMainDropdownOpen ? 'open' : ''}`}>
          {isAuthenticated ? (
            <>
              <span className="headerItem mobileOnly">
                <FontAwesomeIcon
                  icon={faClose}
                  className="linkText"
                  style={{ float: 'right', color: '#0E0F11', fontSize: '24px', paddingTop: '20px' }}
                  onClick={handleClickEvent(() => setIsMainDropdownOpen(!isMainDropdownOpen))}
                />
              </span>

              <a onClick={handleClickEvent(clickLogo)} className="headerItem">
                <Icon
                  icon="Home"
                  color="Purple"
                  customClass="header_nav_icon"
                  customStyle={{ marginTop: '-10px' }}
                />
                &nbsp;&nbsp; My Projects
              </a>
              <a onClick={handleClickEvent(handleScriptUpload)} className="headerItem">
                <Icon
                  icon="Add"
                  color="Purple"
                  customClass="header_nav_icon"
                  customStyle={{ marginTop: '-10px' }}
                />
                &nbsp;&nbsp; New Project
              </a>

              <a href="/app/profile" className="headerItem mobileOnly">
                <FontAwesomeIcon
                  icon={faUser}
                  style={{ color: '#7A64EB', marginBottom: '0px' }}
                  className="header_nav_icon"
                />
                &nbsp;&nbsp; My Profile
              </a>

              {(isDropdownOpen || isMainDropdownOpen) && user.isStaff && isMobile ? (
                <AdminMenu />
              ) : null}

              <span className="headerItem mobileOnly">
                <FontAwesomeIcon icon={faUser} className="linkText userIcon header_nav_icon" />
                &nbsp;&nbsp;
                <Logout useClass="dropdownMenuItem" />
              </span>

              <span
                className="headerItem userIconA nonMobileOnly"
                onClick={handleClickEvent(() => setIsDropdownOpen(!isDropdownOpen))}
              >
                <FontAwesomeIcon icon={faUser} className="linkText userIcon" />
                {(isDropdownOpen || isMainDropdownOpen) && !blockNav ? (
                  <div className="dropdownMenuItem">
                    {user.isStaff ? <AdminMenu /> : null}
                    <a className="dropdownItem" href="/app/profile">
                      My Profile
                    </a>
                    <Logout useClass="dropdownItem" />
                  </div>
                ) : null}
              </span>
            </>
          ) : (
            <></>
          )}
        </nav>
      </header>
    </>
  );
};

export default Header;
