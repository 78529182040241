// Import Libraries
import React, { createContext, useState, useEffect } from 'react';

// Create a context
const ScriptContext = createContext();

// Create a context provider component
export const ScriptProvider = ({ children }) => {
  const [script, setScript] = useState({ scenes: [] });

  const updateScriptScene = (scenes) => {
    if (!scenes || scenes.length === 0) return;

    setScript((existingScript) => ({
      ...existingScript,
      scenes: scenes,
    }));
  };

  return (
    <ScriptContext.Provider value={{ script, setScript, updateScriptScene }}>
      {children}
    </ScriptContext.Provider>
  );
};

export default ScriptContext;
