import React from 'react';

function LoadingSpinner({ size, classNames }) {
  if (!size) size = '30px';
  return (
    <img
      src="/Spinner-2.9s-297px.gif"
      className={`loadingIcon icon ${classNames}`}
      style={{ height: size, width: size }}
      alt="Loading.."
    />
  );
}

export default LoadingSpinner;
