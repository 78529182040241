// Libraries
import React, { useState } from 'react';

// Construct Component
const AdminButton = () => {
  return (
    <>
      <div>Admin</div>
      <a className={`dropdownItem admin`} href="/admin/users">
        Manage Users
      </a>
      <a className={`dropdownItem admin`} href="/admin/subscriptions">
        Manage Subscriptions
      </a>
    </>
  );
};

export default AdminButton;
