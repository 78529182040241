// Import Libraries
import React, { useContext } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

// Setup Application Contexts
import SessionContext from '../../context/Session';

// Construct Component
function GeneralModal() {
  const { generalModalDetails, setGeneralModalDetails } = useContext(SessionContext);

  if (!generalModalDetails) {
    return null; // Return null if generalModalDetails is undefined
  }

  function setModalIsOpen(open) {
    if (!open) {
      setGeneralModalDetails({
        open: false,
        header: '',
        message: '',
        wide: false,
      });
    }
  }

  return (
    <>
      <Modal
        isOpen={generalModalDetails.open}
        onRequestClose={() => setModalIsOpen({ open: false })}
        className={`modal ${generalModalDetails.wide ? 'wide' : ''}`}
      >
        <button className="close-button" onClick={() => setModalIsOpen(false)}>
          <FontAwesomeIcon icon={faClose} />
        </button>
        <div className="modalHeader">
          {generalModalDetails.header && <div className="bold">{generalModalDetails.header}</div>}
        </div>
        <div className="modalContent">
          {generalModalDetails.message && <div>{generalModalDetails.message}</div>}
        </div>
      </Modal>
    </>
  );
}

export default GeneralModal;
