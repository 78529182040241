// Import Libraries
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faCalendar } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';

// Import Services
import AdminUtils from '../../services/utils';
import AdminSubSvc from '../../services/adminSubscriptionService';

import 'react-datepicker/dist/react-datepicker.css';

// Construct Component
function UpdateCreditsModal({ processUser, allowance, isModalOpen, setModalIsOpen }) {
  const [credits, setCredits] = useState(0);
  const [comments, setComments] = useState('');
  //const [useBy, setUseBy] = useState(new Date());
  const [availableCredits, setAvailableCredits] = useState(0);
  const [message, setMessage] = useState('');

  const saveChanges = async () => {
    let useCredits = updateCredits(credits);
    if (useCredits === -1) return;
    /*
    let useByDate = updateUseBy(useBy);
    if (useByDate === -1) return;

    useByDate = new Date(useBy);
    useByDate.setHours(0, 0, 0, 0);
    */
    if (credits) {
      let updated = await AdminSubSvc.subtractFromAllowance(
        processUser,
        allowance,
        credits,
        comments
      );
      if (updated) {
        setModalIsOpen(false);
      }
    } else {
      setMessage('Please recheck your entries and try again.');
    }

    setModalIsOpen(false);
  };

  const updateCredits = (value) => {
    if (value > availableCredits) {
      setMessage('You cannot subtract more credits than are available');
      return -1;
    } else {
      setCredits(value);
      setMessage(null);
      return value;
    }
  };

  /*
  const updateUseBy = (value) => {
    const now = new Date();
    const newUseBy = new Date(value);
    if (newUseBy < now) {
      setMessage('You cannot set a date in the past');
      return -1;
    } else {
      setUseBy(newUseBy);
      setMessage(null);
      return newUseBy;
    }
  };
*/

  useEffect(() => {
    setAvailableCredits(allowance.allowance - allowance.creditsUsed);
    /*
    const now = new Date();
    const useByDate = new Date(now.setMonth(now.getMonth() + 1));
    setUseBy(useByDate);
    */
  }, [allowance]);

  return (
    <div>
      <Modal
        isOpen={isModalOpen !== false}
        onRequestClose={() => setModalIsOpen(false)}
        className="modal"
      >
        <button className="close-button" onClick={() => setModalIsOpen(false)}>
          <FontAwesomeIcon icon={faClose} />
        </button>
        <div className="modalHeader">
          <div className="bold">
            Subtract credits for User: {processUser.id} Credit Allowance {allowance.id}{' '}
          </div>
        </div>
        <div className="modalContent">
          <div className="fullWidthContainer Padded">
            {processUser.first_name} {processUser.last_name} ({processUser.email}) currently has{' '}
            {availableCredits} credits available
          </div>
          <div className="fullWidthContainer Padded">
            You can subtract credits from this allowance here.
            <br />
            <br />
            To add credits, use the + icon in the header of the credits table.
          </div>
          <div className="fullWidthContainer Padded">
            <span className="Padded TwoPortionFlex">Subtract Credits: </span>
            <span className="Padded ThreePortionFlex">
              <input value={credits} onChange={(e) => updateCredits(e.target.value)} />
            </span>
            <span className="Padded TwoPortionFlex"></span>
          </div>
          {/*
          <div className="fullWidthContainer Padded">
            <span className="Padded TwoPortionFlex">To use by:</span>
            <span className="Padded FivePortionFlex">
              <DatePicker selected={useBy} onChange={(value) => updateUseBy(value)} />
            </span>
          </div>
  */}
          <div className="fullWidthContainer Padded">
            <span className="Padded TwoPortionFlex">Comments: </span>
            <span className="Padded ThreePortionFlex">
              <textarea value={comments} onChange={(e) => setComments(e.target.value)} />
            </span>
            <span className="Padded TwoPortionFlex"></span>
          </div>

          <div className="fullWidthContainer Padded">
            <span className="OnePortionFlex"></span>
            <button className="OnePortionFlex buttonClone" onClick={() => saveChanges()}>
              Save
            </button>
          </div>

          {message ? <div className="fullWidthContainer Padded orangeText">{message}</div> : null}
        </div>
      </Modal>
    </div>
  );
}

export default UpdateCreditsModal;
