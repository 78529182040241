// Libraries
import React, { useState, useContext, useEffect } from 'react';
import io from 'socket.io-client';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';

// Components
import GeneralModal from '../components/Modals/GeneralModal.js';
import ProFlag from '../components/Elements/ProFlag.js';
import Icon from '../components/Elements/Icon.js';
import CollaboratorDropdown from '../components/Fragments/Project/collaboratorDropdown.js';
import CollaboratorBubbleList from '../components/Fragments/Project/collaboratorBubbleList.js';

//Services
import ScriptService from '../services/scriptService';
import ScriptWebSocket from '../services/scriptWebSocket';
import utilities from '../services/utilities';

// Set User Context
import { UserContext } from '../context/User';
import SessionContext from '../context/Session';

// Construct Component
const ScriptList = ({ script, updateScript, onDeleteScript, creditsAvailable }) => {
  const { user } = useContext(UserContext);
  const { isMobile, generalModalDetails, setGeneralModalDetails } = useContext(SessionContext);
  const [showCollabDropdown, setShowCollabDropdown] = useState(false);

  /*const [generalModalDetails, setGeneralModalDetails] = useState({
    open: false,
    header: '',
    message: '',
  });*/

  const formatCreatedAt = (createdAt) => {
    const date = new Date(createdAt);

    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear().toString().slice(-2);
    const options = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    const time = date.toLocaleString('en-US', options);

    return `${month}/${day}/${year} at ${time}`;
  };

  const removeMyAccess = async (scriptId) => {
    let collab = script.collaborators.editors.find((c) => c.user_id === user.id);
    if (!collab) {
      collab = script.collaborators.viewers.find((c) => c.user_id === user.id);
    }
    if (!collab) {
      console.error('Could not find user in collaborators list');
      return;
    }
    let removed = await ScriptService.editCollaborator(scriptId, collab.id, 'removeAccess');
    if (removed) {
      onDeleteScript(scriptId);
    }
    setGeneralModalDetails({ open: false, header: null, message: null });
  };

  async function confirmedDeleteScript(scriptId) {
    setGeneralModalDetails({ open: false, header: null, message: null });
    let deleted = await ScriptService.deleteScript(scriptId);
    if (deleted) {
      onDeleteScript(scriptId);
    } else {
      console.error('Failed to delete script.');
      //TODO: Add Delete Script Failure condition
    }
  }

  const callRemoveMeModal = (scriptId, scriptName) => {
    setGeneralModalDetails({
      open: true,
      header: (
        <span>
          <FontAwesomeIcon
            icon={faTrashCan}
            style={{ paddingRight: '20px', color: '#E35640', fontSize: '18' }}
          />
          Remove yourself as a collaborator for this project
        </span>
      ),
      message: (
        <div>
          You cannot delete the script for other users, but you can remove yourself as a
          collaborator which will immediately revoke your access and remove it from your dashboard.{' '}
          <br />
          <br />
          We will also let the owner know that you are no longer collaborating on this script. Would
          you like to remove yourself from this project?
          <br />
          <br />
          <div className="fullWidthContainer">
            <button
              className="buttonGray OnePortionFlex"
              onClick={() =>
                setGeneralModalDetails({
                  open: false,
                  header: null,
                  message: null,
                })
              }
            >
              Cancel
            </button>
            <button
              className="OnePortionFlex"
              style={{ backgroundColor: '#E35640', color: '#fff', minWidth: '110px' }}
              onClick={() => removeMyAccess(scriptId)}
            >
              Remove me
            </button>
          </div>
        </div>
      ),
    });
    return;
  };

  const deleteScriptModal = (scriptId, scriptName) => {
    if (script.level !== 'owner') {
      callRemoveMeModal(scriptId, scriptName);
      return;
    }
    setGeneralModalDetails({
      open: true,
      header: (
        <span>
          <FontAwesomeIcon
            icon={faTrashCan}
            style={{ paddingRight: '20px', color: '#E35640', fontSize: '18' }}
          />
          Delete Script
        </span>
      ),
      message: (
        <div>
          Are you sure you want to delete {scriptName}? This action cannot be undone and all scenes
          you shotlisted will be lost. <br />
          <br />
          Deleting this project will not reset your script credits. If you need more credits, please{' '}
          <a
            href="https://www.shotkraft.com/contact-us"
            style={{ padding: '0px' }}
            className="linkText"
          >
            contact us
          </a>
          .
          <br />
          <br />
          <div className="fullWidthContainer">
            <button
              className="buttonGray OnePortionFlex"
              onClick={() =>
                setGeneralModalDetails({
                  open: false,
                  header: null,
                  message: null,
                })
              }
            >
              Cancel
            </button>
            <button
              className="OnePortionFlex"
              style={{ backgroundColor: '#E35640', color: '#fff', minWidth: '110px' }}
              onClick={() => confirmedDeleteScript(scriptId)}
            >
              Delete Script
            </button>
          </div>
        </div>
      ),
    });
  };

  const processPage = async (pageNumber) => {
    script.last_processed_page = pageNumber;
  };

  const addScene = (scene) => {
    script.scenes.push(scene);
  };

  const goToScript = (scriptId) => {
    window.location.href = `/app/ShotList/${scriptId}`;
  };

  return (
    <>
      {generalModalDetails && (
        <GeneralModal
          generalModalDetails={generalModalDetails}
          setModalIsOpen={setGeneralModalDetails}
        />
      )}
      {script.page_count !== script.last_processed_page && script.path ? (
        <ScriptWebSocket scriptId={script.id} processPage={processPage} addScene={addScene} />
      ) : null}
      <div className={isMobile ? 'fullWidthContainer' : 'OnePortionFlex'}>
        <div
          className="projectCard"
          style={{
            ...(isMobile && { width: '100%' }),
          }}
        >
          <div className="FullWidthFlex projectCardHeader">
            <span className="ThreePortionFlex projectCardHeaderText">{script.script_name}</span>
            <span className="OnePortionFlex">
              <button
                onClick={() => goToScript(script.id)}
                className="buttonCloneLightPurple"
                style={{ marginTop: '-3px', float: 'right', minWidth: '120px' }}
              >
                View Shotlist
              </button>
            </span>
          </div>

          <div className="projectCardBody">
            <div className="FullWidthFlex">Created {formatCreatedAt(script.created_at)}</div>
            <div className="FullWidthFlex">
              {' '}
              {script.script_path && script.page_count !== script.last_processed_page ? (
                'Processing PDF File'
              ) : (
                <span>
                  <span style={{ paddingRight: '10px' }}>{script.scene_count} Scenes</span>|
                  <span style={{ paddingLeft: '10px' }}>{script.shot_count} Shots</span>
                </span>
              )}
            </div>
            <div className="FullWidthFlex" style={{ marginTop: '10px' }}>
              <span style={{ marginTop: '2px', paddingRight: '5px' }}>Auto generate:</span>
              <span style={{ marginTop: '2px' }}>
                {script && script.features ? (
                  <>
                    {script.features.toLowerCase() === 'pro' ||
                    script.features.toLowerCase() === 'pro freemium' ? (
                      <>On</>
                    ) : script.features.toLowerCase() === 'pro freemium10' ? (
                      <>10 Free Scenes</>
                    ) : script.features.toLowerCase() === 'basicplus' ? (
                      <>5 Free Scenes</>
                    ) : (
                      <>Off</>
                    )}
                  </>
                ) : (
                  <>Off</>
                )}
              </span>

              {!script || !script.features || script.features.toLowerCase() !== 'pro' ? (
                <span style={{ paddingLeft: '5px' }}>
                  <ProFlag
                    type="pro"
                    featureMsg="Upgrade this script to Pro"
                    scriptLevel={script && script.features ? script.features : 'basic'}
                    scriptId={script.id}
                  />
                </span>
              ) : null}

              {/*script &&
              script.features &&
              (script.features.toLowerCase() === 'pro' ||
                script.features.toLowerCase() === 'pro freemium') ? (
                <span style={{ marginTop: '2px' }}>On</span>
              ) : (
                <span>
                  {script && script.features && script.features.toLowerCase() === 'basicplus'
                    ? 'Free Trial: First 5 Scenes Only'
                    : 'Off'}
                  <span style={{ paddingLeft: '5px' }}>
                    <ProFlag
                      type="pro"
                      featureMsg="Upgrade this script to Pro"
                      scriptLevel={script && script.features ? script.features : 'basic'}
                      scriptId={script.id}
                    />
                  </span>
                </span>
              )*/}
            </div>
            <div className="FullWidthFlex">
              Project Type: {script.script_path ? 'PDF Script' : 'Copy/Paste Script'}
            </div>
          </div>

          <div
            className="FullWidthFlex projectCardFooter mobileVerticalFlex"
            style={{ paddingBottom: '0px', paddingTop: '0px' }}
          >
            <span className="SevenPortionFlex">
              <div className="fullWidthContainer">
                <span
                  className="TwoPortionFlex"
                  style={{ textAlign: 'left', alignContent: 'center' }}
                >
                  {script.level.charAt(0).toUpperCase() + script.level.slice(1)}
                </span>
                <span className="FivePortionFlex Padded" style={{ marginTop: '5px' }}>
                  <CollaboratorBubbleList
                    collaborators={script.collaborators}
                    setShowCollabDropdown={setShowCollabDropdown}
                    level={script.level}
                  />
                </span>
              </div>
              {script.level === 'owner' && (
                <span style={{ position: 'relative', display: 'block' }}>
                  {showCollabDropdown && (
                    <CollaboratorDropdown
                      script={script}
                      updateScript={updateScript}
                      setShow={setShowCollabDropdown}
                    />
                  )}
                </span>
              )}
            </span>
            <span className="OnePortionFlex" style={{ alignContent: 'center' }}>
              <Icon
                icon="Trash"
                color="Orange"
                onClick={() => deleteScriptModal(script.id, script.script_name)}
                customStyle={{
                  float: 'right',
                  paddingLeft: '30px',
                  paddingRight: '15px',
                  marginTop: '-5px',
                }}
              />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScriptList;
