// Import Libraries
import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';

// Import Components
import CheckoutForm from './CheckoutForm';
import StripeWrapper from './StripeWrapper';

// Import Services
import subService from '../../../services/subscriptionService';

// Context
import { UserContext } from '../../../context/User';

// Setup Modal to Root
Modal.setAppElement('#root');

const PurchaseSubscription = ({ onRequestClose, plan, scriptId, nextStep, userDetails }) => {
  const { user, setUser } = useContext(UserContext);
  const [newCard, setNewCard] = useState(false);
  const [hasCards, setHasCards] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  let useUser = userDetails ? userDetails : user;

  const continueWithTrial = async () => {
    let submitted = await subService.createSubscription(
      useUser.id,
      plan.details.ext_product,
      'trial',
      scriptId
    );
    if (submitted) {
      nextStep(4);
    }
  };

  useEffect(() => {
    if (useUser && useUser.cards && useUser.cards.length > 0) {
      setHasCards(true);
    } else if (useUser) {
      setHasCards(false);
      setNewCard(true);
    }
    setIsLoading(false);
  }, [useUser]);

  return (
    <>
      {' '}
      {plan ? (
        <>
          <div className="modalHeader bold fullWidthContainer">
            <span className="TwoPortionFlex">Process Payment</span>
            <span className="OnePortionFlex"></span>

            {useUser.id !== user.id && plan.details && plan.details['include_trial'] ? (
              <button
                className="TwoPortionFlex buttonClone MinSmall"
                onClick={() => {
                  continueWithTrial();
                }}
              >
                Skip Payment until after Trial
              </button>
            ) : null}
          </div>
          <div className="modalContent">
            <div className="Padded" style={{ paddingBottom: '30px' }}>
              <span className="bold">Subscription:</span>
              <span style={{ float: 'right' }}>
                {plan.label}
                {` `}
                {plan.details.price.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 2,
                })}
              </span>
            </div>
            {!isLoading && (
              <div style={{ paddingBottom: '30px' }}>
                {hasCards && !newCard ? (
                  <CheckoutForm
                    plan={plan}
                    setNewCard={setNewCard}
                    scriptId={scriptId}
                    nextStep={nextStep}
                  />
                ) : (
                  <StripeWrapper
                    plan={plan}
                    hasCards={hasCards ? true : false}
                    setNewCard={setNewCard}
                    scriptId={scriptId}
                    nextStep={nextStep}
                  />
                )}
              </div>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default PurchaseSubscription;
