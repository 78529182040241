import { useEffect, useRef } from 'react';

const useIdleTimer = (onIdle, timeout = 30 * 60 * 1000) => {
  const lastActiveTime = useRef(new Date());

  useEffect(() => {
    const checkIdle = () => {
      const currentTime = new Date();
      const timeDifference = currentTime - lastActiveTime.current;
      if (timeDifference >= timeout) {
        onIdle();
      }
    };

    const resetTimer = () => {
      lastActiveTime.current = new Date();
    };

    // Attach event listeners for user interactions
    document.addEventListener('mousemove', resetTimer);
    document.addEventListener('keydown', resetTimer);

    // Set an interval to check for idle status
    const intervalId = setInterval(checkIdle, 1000); // Check every second

    return () => {
      // Cleanup event listeners and interval on unmount
      clearInterval(intervalId);
      document.removeEventListener('mousemove', resetTimer);
      document.removeEventListener('keydown', resetTimer);
    };
  }, [onIdle, timeout]);

  return lastActiveTime.current;
};

export default useIdleTimer;
