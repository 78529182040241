function params(method, body) {
  let header = {};
  if (body) {
    header = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    };
    body = JSON.stringify(body);
    return { method: method, headers: header, body: body };
  } else {
    header = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    return { method: method, headers: header };
  }
}

const subscriptionService = {
  /**
   * Fetches available subscription plans from the API, including ext prod information.
   *
   * @returns {Promise<Object[]>} An array of available subscription plans.
   * @throws {Error} If an error occurs while fetching the plans.
   */
  fetchAvailablePlans: async function () {
    try {
      const url = `${process.env.REACT_APP_API_URL}/subscribe/availablePlans`;
      const plans = await fetch(url, params('GET'));

      if (plans.ok) {
        const respond = await plans.json();
        if (respond) {
          let mapped_values = respond
            ? respond.map((item) => ({ label: item.frequency, value: item.id, details: item }))
            : [];
          return mapped_values;
        } else {
          return [];
        }
      } else {
        console.error('Error fetching script dictionary entry');
        return [];
      }
    } catch (err) {
      console.error(err);
    }
  },

  /**
   * Fetches available credits for a user from the API.
   *
   * @param {number|string} userId - REQUIRED: The identifier of the user for whom the subscription is being created.
   *
   * @returns {Promise<Object[]>} An array of available subscription plans.
   * @throws {Error} If an error occurs while fetching the plans.
   */
  fetchAvailableCredits: async function (userId) {
    try {
      const url = `${process.env.REACT_APP_API_URL}/subscribe/userCreditsAvailable/${userId}`;
      const credits = await fetch(url, params('GET'));

      if (credits.ok) {
        const respond = await credits.json();
        if (respond) {
          return respond;
        } else {
          return [];
        }
      } else {
        console.error('Error fetching script dictionary entry');
        return [];
      }
    } catch (err) {
      console.error(err);
    }
  },

  /**
   * Retrieves user subscription data from the backend, if scriptId is included, upgrades that script to pro
   *
   * @param {number|string} userId - REQUIRED: The identifier of the user for whom the subscription is being created.
   * @param {Object} product - REQUIRED: The product object for which the subscription is to be created. Must include a price object with an id.
   * @param {string} payMethod - REQUIRED: The payment method identifier to be used for the subscription.
   * @param {string} scriptId - OPTIONAL: The script identifier associated with the subscription (not used in current implementation).
   * @returns {Promise<Object|null>} A promise that resolves to an about us object if the request is successful,
   * or null if the request fails or encounters an error.
   */
  createSubscription: async function (userId, product, payMethod, scriptId) {
    try {
      if (!userId || !product || !product.price || !payMethod) return null;

      let url = `${process.env.REACT_APP_API_URL}/subscribe/subscribe`;
      let requestBody = {
        extProdId: product.id,
        extPriceId: product.price.id,
        extPM: payMethod,
        userId: userId,
        scriptId: scriptId,
      };
      const response = await fetch(url, params('POST', requestBody));

      if (response.ok) {
        return true;
      }
      return null;
    } catch (error) {
      return null;
    }
  },

  /**
   * Retrieves subscription details for a given user from the server.
   * @param {string} userId - REQUIRED: The ID of the user whose subscription details are to be retrieved.
   * @returns {Object|null} An object containing the user's subscription details if successful, or null if the user ID is invalid or an error occurs.
   * @throws {Error} If an error occurs during the fetch operation.
   */
  getUserSubscriptionDetail: async function (userId) {
    try {
      if (!userId) return null;
      let url = `${process.env.REACT_APP_API_URL}/subscribe/userSubscriptionDetail/${userId}`;
      const response = await fetch(url, params('GET'));

      if (response.ok) {
        const detail = await response.json();
        console.log('details', detail);
        return detail;
      }
      return null;
    } catch (error) {
      return null;
    }
  },

  /**
   * Retrieves subscription details for a given user from the server.
   * @param {string} userId - REQUIRED: The ID of the user whose subscription details are to be retrieved.
   * @returns {Object|null} An object containing the user's subscription details if successful, or null if the user ID is invalid or an error occurs.
   * @throws {Error} If an error occurs during the fetch operation.
   */
  cancelSubscription: async function (subscription, userId) {
    try {
      if (!userId) return null;
      let url = `${process.env.REACT_APP_API_URL}/subscribe/cancelRecurring`;
      let requestBody = {
        subscription: subscription,
        userId: userId,
      };
      const response = await fetch(url, params('POST', requestBody));

      if (response.ok) {
        const detail = await response.json();
        return detail;
      }
      return null;
    } catch (error) {
      return null;
    }
  },
};

export default subscriptionService;
