// Import Libraries
import React, { useEffect, useState, useContext } from 'react';

// Services
import userService from '../services/userService';
import dictionaryService from '../services/dictionaryService';

// Components
import SelectBox from '../components/Elements/Select';
import UserDetails from '../components/UserComponents/userDetails';
import AboutUs from '../components/UserComponents/aboutUs';
import UserSubscription from '../components/UserComponents/subscription';

// Get Context
import { UserContext } from '../context/User';

function Profile({ userDetails = {}, isStaff }) {
  const { user } = useContext(UserContext);
  const [error, setError] = useState(null);
  const [aboutUsDict, setAboutUsDict] = useState([]);
  const [useUser, setUseUser] = useState({});
  const [userData, setUserData] = useState({
    first_name: userDetails.first_name || user.first_name || '',
    last_name: userDetails.last_name || user.last_name || '',
    about_us: '',
    about_us_text: '',
  });

  /*
  const updateUser = async () => {
    try {
      let data = userData;
      data.id = user.id;
      let userUpdate = await userService.updateProfileFields(data);
    } catch (error) {
      setError(error.message);
    }
  };
  */

  /*
  const updateAboutUs = async (value) => {
    setUserData({ ...userData, about_us: value });
  };
  *

  useEffect(() => {
    const fetchData = async () => {
      let getAboutUs = await dictionaryService.fetchDictionary('about_us');
      setAboutUsDict(getAboutUs);
    };

    setUseUser(userDetails.id ? userDetails : user);

    if (useUser) {
      fetchData();
    }
  }, [user]);
*/

  useEffect(() => {
    const updateUser = async () => {
      if (userDetails.id) {
        setUseUser(userDetails);
      } else {
        const fetchedUser = await userService.getUserData();
        setUseUser(fetchedUser || user);
      }
    };

    if (!useUser || !useUser.id) {
      updateUser();
    }
  }, [userDetails, user]);

  console.log('use user', useUser);

  return (
    <div className="Page">
      {!user.isStaff ? (
        <div className="bold large">Welcome {`${useUser.first_name || ''}`}</div>
      ) : null}
      {useUser.id ? (
        <>
          <div className="fullWidthContainer" style={{ paddingTop: '20px' }}>
            <div className="FivePortionFlex">
              <UserDetails userDetails={useUser} />
            </div>

            <div className="OnePortionFlex">&nbsp;</div>

            <div className="FivePortionFlex">
              <AboutUs userDetails={useUser} />
            </div>
          </div>
          <div className="fullWidthContainer">
            <UserSubscription userDetails={useUser} />
          </div>
        </>
      ) : null}
    </div>
  );
}

export default Profile;
