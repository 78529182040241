import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import Icon from '../Icon';

function ImageUpload({ uploadAttachment, image, deleteAttachment, readOnly }) {
  const fileInputRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);

  const handleFileChange = (event) => {
    if (readOnly) return;
    const file = event.target.files[0];
    uploadAttachment(file);
  };

  const handleDragOver = (event) => {
    if (readOnly) return;
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    if (readOnly) return;
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    if (readOnly) return;
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    uploadAttachment(file);
  };

  return (
    <span className="OnePortionFlex Padded" style={{ alignSelf: 'center', textAlign: 'center' }}>
      <input
        type="file"
        id="fileInput"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
        disabled={readOnly}
      />
      <span
        className={`${isDragging ? 'dragging' : ''}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={() => {
          if (!readOnly) fileInputRef.current.click();
        }}
      >
        {image ? (
          <img src={image} alt="Shot Image" className="loading-box shotImage" />
        ) : (
          <FontAwesomeIcon icon={faCloudArrowUp} style={{ fontSize: '42px' }} />
        )}
      </span>

      {image && !readOnly ? (
        <Icon
          icon="Trash"
          color="Orange"
          size="22"
          customClass="closeTrashIcon"
          customStyle={{ margin: '0px 0px 0px -29px' }}
          onClick={() => deleteAttachment()}
        />
      ) : null}
    </span>
  );
}

export default ImageUpload;
