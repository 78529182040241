// Import Libraries
import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

// Import Components
import StripeWrapper from '../Fragments/Payments/StripeWrapper.js';
import CheckoutForm from '../Fragments/Payments/CheckoutForm';

// Context
import { UserContext } from '../../context/User';

// Setup Modal to Root
Modal.setAppElement('#root');

const PaymentDue = ({ modalIsOpen, setModalIsOpen }) => {
  const { user, setUser } = useContext(UserContext);
  const [newCard, setNewCard] = useState(false);
  const [invoice, setInvoice] = useState(null);

  useEffect(() => {
    if (user && user.invoiceDue && user.invoiceDue.length > 0) {
      //getInvoicesDue();
      setInvoice(user.invoiceDue[0]);
    }
  }, [user]);

  return (
    <>
      {invoice ? (
        <Modal isOpen={modalIsOpen} contentLabel="Idle Modal" className="modal wide">
          <div className="close-button" role="button" onClick={() => setModalIsOpen(false)}>
            <FontAwesomeIcon icon={faTimes} />
          </div>

          <div className="modalHeader">
            <div className="bold">Continue your subscription</div>
          </div>
          <div className="modalContent">
            <div className="Padded">
              We need to update your payment information to continue your subscription.
            </div>

            <div className="Padded">
              Your payment of{' '}
              {invoice.amount.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
              })}{' '}
              is due and your subscription has been paused awaiting addition of up to date payment
              information.
            </div>
            <div className="Padded">
              Please add your preferred payment method below. Once your card is processed you will
              immediately regain access to our most advanced features. <br />
              <br />
            </div>
            <div className="Padded">
              {user && user.cards && user.cards.length > 0 && !newCard ? (
                <CheckoutForm invoice={invoice} setNewCard={setNewCard} />
              ) : (
                <StripeWrapper
                  invoice={invoice}
                  hasCards={user && user.cards && user.cards.length > 0 ? true : false}
                  setNewCard={setNewCard}
                />
              )}
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default PaymentDue;
