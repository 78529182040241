// Import Libraries
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faCaretUp,
  faEye,
  faEyeSlash,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';

// Import Services
import AdminUserSvc from '../../services/adminUserService';
import ScriptSvc from '../../../services/scriptService';

// Import Components
import ShotList from '../../../pages/sceneAndShotlistView/scriptView';

// Construct Component
const UserScripts = ({ processUser }) => {
  const [displayScripts, setDisplayScripts] = useState(false);
  const [showScript, setShowScript] = useState(null);
  const [userScripts, setUserScripts] = useState([]);

  const getUserScripts = async () => {
    let userScripts = await AdminUserSvc.getUserScripts(processUser.id);
    setUserScripts(userScripts);
  };

  const handleScriptDownload = async (path, scriptId) => {
    try {
      const pdfUrl = await ScriptSvc.getPdf(path, scriptId);
      const pathParts = path.split('/');
      const fileName = pathParts[pathParts.length - 1];
      const downloadLink = document.createElement('a');
      downloadLink.href = pdfUrl;
      downloadLink.download = fileName;
      downloadLink.click();
      URL.revokeObjectURL(pdfUrl);
    } catch (error) {
      console.error(`Error downloading PDF: ${error.message}`);
    }
  };

  useEffect(() => {
    getUserScripts();
  }, [processUser]);

  return (
    <div style={{ padding: '10px' }}>
      {/*
      <div className="fullWidthFlex">
        <span className="TenPortionFlex large">User Scripts</span>
        <span
          className="OnePortionFlex purpleLink"
          onClick={() => setDisplayScripts(!displayScripts)}
        >
          {displayScripts && <FontAwesomeIcon icon={faCaretUp} />}
          {!displayScripts && <FontAwesomeIcon icon={faCaretDown} />}
        </span>
      </div>


      {displayScripts && (
      */}
      <div className={`card flatLeftCard TenPortionFlex`} style={{ marginRight: '30px' }}>
        <div className="cardHeader fullWidthFlex bold" style={{ marginRight: '25px' }}>
          <span className="OnePortionFlex FlexWrap">ID</span>
          <span className="TwoPortionFlex FlexWrap">Name</span>
          <span className="OnePortionFlex FlexWrap">File</span>
          <span className="TwoPortionFlex FlexWrap">Created At</span>
          <span className="OnePortionFlex FlexWrap">Pages</span>
          <span className="TwoPortionFlex FlexWrap">Deleted At</span>
          <span className="OnePortionFlex FlexWrap">Scenes</span>
          <span className="OnePortionFlex FlexWrap">Shots</span>
          <span className="OnePortionFlex FlexWrap"></span>
        </div>

        <div className="hiddenScroll" style={{ height: '400px' }}>
          {userScripts.map((script) => (
            <div
              key={script.id}
              className={`card flatLeftCard TenPortionFlex ${
                showScript === script.id ? 'selected' : null
              }`}
            >
              <div className="cardHeader fullWidthFlex">
                <span className="OnePortionFlex FlexWrap">{script.id}</span>
                <span className="TwoPortionFlex FlexWrap">{script.script_name}</span>
                <span
                  className="OnePortionFlex FlexWrap linkText"
                  onClick={() => handleScriptDownload(script.script_path, script.id)}
                >
                  <FontAwesomeIcon className="linkText" icon={faDownload} />
                  <span style={{ display: 'none' }}>{script.script_path}</span>
                </span>
                <span className="TwoPortionFlex FlexWrap">{script.created_at}</span>
                <span className="OnePortionFlex FlexWrap">
                  {script.last_processed_page} of {script.page_count}
                </span>
                <span className="TwoPortionFlex FlexWrap">{script.deleted_at}</span>
                <span className="OnePortionFlex FlexWrap">{script.Scenes}</span>
                <span className="OnePortionFlex FlexWrap">{script.Shots}</span>
                <span className="OnePortionFlex FlexWrap">
                  {showScript !== script.id ? (
                    <FontAwesomeIcon
                      className="linkText"
                      icon={faEye}
                      onClick={() => setShowScript(script.id)}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="linkText orangeText"
                      icon={faEyeSlash}
                      onClick={() => setShowScript(null)}
                    />
                  )}
                </span>
              </div>

              {showScript === script.id && (
                <div className="selectedCard">
                  <ShotList scriptId={script.id} />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {/*
      )}
    */}
    </div>
  );
};

export default UserScripts;
