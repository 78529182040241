// Import Libraries
import React from 'react';

// Import Components
import Select from '../../components/Elements/Select';

// Construct Component
const SearchElement = ({ searchOptions, fieldName, search, onSearch, inputType, values }) => {
  return (
    <>
      <span className="FivePortionFlex fullWidthContainer">
        <div style={{ width: '100%' }}>
          <Select
            selectLabel={``}
            labelStyle={`none`}
            selectOptions={searchOptions}
            selectedValue={search && search.type ? search.type : 'Any'}
            handleSelectChange={(value) => onSearch(fieldName, null, value)}
            className="fullWidthContainer"
          />
        </div>
        {inputType === 'select' ? (
          <div style={{ width: '100%' }}>
            <Select
              selectLabel={``}
              labelStyle={`none`}
              selectOptions={values}
              selectedValue={values[0]}
              handleSelectChange={(value) => onSearch('plan', null, value)}
              className="fullWidthContainer"
              preMapped={true}
            />
          </div>
        ) : search && search.type !== 'Any' ? (
          <div style={{ width: '100%' }}>
            <input
              className="fullWidthContainer"
              defaultValue={search.value}
              onBlur={(e) => onSearch(fieldName, e.target.value)}
            />
          </div>
        ) : null}
      </span>
      <span className="OnePortionFlex"></span>
    </>
  );
};

export default SearchElement;
