// Import Libraries
import React, { useState, useRef, useEffect, useContext, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Import Components
import SceneTable from './sceneTable';
import ShotTable from './shotTable';
import ShotTableHeader from '../../components/Fragments/ShotTableHeader';

// Import Services
import UserService from '../../services/userService';
import ScriptService from '../../services/scriptService';
import Utils from '../../services/utilities';
import ScriptWebSocket from '../../services/scriptWebSocket';
import ProjectSockets from '../../services/projectSockets';

// Context
import { UserContext } from '../../context/User';
import ScriptContext from '../../context/Script';
import SessionContext from '../../context/Session';
import ProjectViewContext from '../../context/ProjectView';

// Construct Component
const ShotList = ({ scriptId }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { script, setScript } = useContext(ScriptContext);
  const scriptRef = useRef(script);

  const { isMobile } = useContext(SessionContext);
  const {
    view,
    setView,
    lockedFields,
    setLockedFields,
    myLockedField,
    setMyLockedField,
    activeCollaborators,
    setActiveCollaborators,
    newUpdate,
    setNewUpdate,
    lockField,
    setLockField,
    unlockField,
    setUnlockField,
  } = useContext(ProjectViewContext);

  const [sceneClass, setSceneClass] = useState('slide-out-left');
  const [shotClass, setShotClass] = useState('slide-out-right');
  const [error, setError] = useState(false);
  const componentRef = useRef(null);

  useEffect(() => {
    if (location.state && location.state.uploadedFile) {
      setView({ ...view, uploadedFile: location.state.uploadedFile });
    }
  }, [location.state]);

  useEffect(() => {
    if (isMobile) {
      if (view.onScreen === 'scene') {
        setSceneClass('offscreen-left');
        setShotClass('slide-out-right');
        setTimeout(() => {
          setSceneClass('slide-in');
        }, 100);
        setTimeout(() => {
          setShotClass('hidden');
        }, 500);
      } else if (view.onScreen === 'shot') {
        setShotClass('offscreen-right');
        setSceneClass('slide-out-left');
        setTimeout(() => {
          setShotClass('slide-in');
        }, 100);
        setTimeout(() => {
          setSceneClass('hidden');
        }, 500);
      }
    }
  }, [view.onScreen]);

  const setupPageView = async (tempScript) => {
    let manualEntry = false;
    let pdfPath = null;
    if (tempScript.script_path) {
      pdfPath = await ScriptService.getPdf(tempScript.script_path, tempScript.id);
    }
    if (!pdfPath) {
      manualEntry = true;
    }
    let status = 0;
    if (script?.scenes[0]?.ai_status > 0) {
      status = script.scenes[0].ai_status;
    }
    setView((prevView) => ({
      ...prevView,
      uploadedFile: pdfPath || prevView.uploadedFile,
      manualEntry: manualEntry,
      selectedIndex: 0,
      viewColumns: window.innerWidth < 768 ? 1 : 2,
      isLoading: false,
      sceneImagesAllowed:
        tempScript?.features?.toLowerCase() === 'basic' && status === 0 ? false : true,
    }));
  };
  const setupPage = async () => {
    let pageViewSet = false;
    if (scriptId && user) {
      try {
        let tempScript = await ScriptService.getScriptById(scriptId);
        if (!tempScript) {
          navigate('/app/home');
          return;
        }
        for (let i = 0; i < tempScript.scenes.length; i++) {
          tempScript.scenes[i].shot_list = Utils.buildTempShot(tempScript, tempScript.scenes[i]);
        }
        let level = null;
        if (tempScript?.collaborators?.owner?.user_id === user.id) {
          level = 'owner';
        } else if (
          tempScript?.collaborators?.editors?.find((editor) => editor.user_id === user.id)
        ) {
          level = 'editor';
        } else if (
          tempScript?.collaborators?.viewers?.find((viewer) => viewer.user_id === user.id)
        ) {
          level = 'viewer';
        } else if (window.location.pathname.substring(0, 6) !== '/admin') {
          level = 'owner';
        } else {
          let validLocalStaffToken = await UserService.validateStaffToken();
          if (validLocalStaffToken) {
            level = 'owner';
          } else {
            navigate('/app/home');
            return;
          }
        }
        let locks = await ScriptService.getActiveLocks(tempScript.id);
        setLockedFields(locks);
        tempScript.level = level;
        setScript((currentScript) => ({
          ...currentScript,
          ...tempScript,
        }));

        if (tempScript.page_count !== tempScript.last_processed_page) {
          let counter = 0;
          while (counter < 20 && tempScript.page_count !== tempScript.last_processed_page) {
            if (pageViewSet === false && tempScript.scenes.length > 0) {
              pageViewSet = true;
              setupPageView(tempScript);
            }
            await new Promise((resolve) => setTimeout(resolve, 4000));
            tempScript = await ScriptService.getScriptById(scriptId);
            counter++;
            for (let i = 0; i < tempScript.scenes.length; i++) {
              tempScript.scenes[i].shot_list = Utils.buildTempShot(
                tempScript,
                tempScript.scenes[i]
              );
            }
            tempScript.level = level;
            setScript((currentScript) => ({
              ...currentScript,
              ...tempScript,
            }));
          }
        } else {
          setupPageView(tempScript);
        }

        if (tempScript.scenes.length === 0) {
          setError(true);
        }
        return true;
      } catch (err) {
        console.error('Error setting up page:', err);
        setError(true);
      }
    }
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      ProjectSockets.disconnectSocket();
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [user]);

  useEffect(() => {
    const initializeProjectPage = async () => {
      try {
        const setupSuccess = await setupPage();
        if (setupSuccess) {
          ProjectSockets.connectSocket(
            scriptId,
            user.id,
            setLockedFields,
            setView,
            setActiveCollaborators,
            scriptRef,
            setScript,
            setNewUpdate
          );
        }
      } catch (error) {
        console.error('Error initializing script page:', error);
      }
    };

    if (scriptId && user && user.id) {
      UserService.addActionLog('View Script Shot List', scriptId, 'script');
      initializeProjectPage();
    }

    return () => {
      ProjectSockets.disconnectSocket();
    };
  }, [scriptId, user]);

  useEffect(() => {
    if (
      script &&
      script.scenes &&
      script.scenes.length > 0 &&
      script.scenes[0].id &&
      view.isLoading
    ) {
      setView((prevView) => ({
        ...prevView,
        isLoading: true,
      }));
    }
  }, [script.scenes]);

  useEffect(() => {
    scriptRef.current = script;
  }, [script]);

  const lockFieldLogic = useCallback((fieldId) => {
    if (!fieldId) return;
    const element = document.getElementById(fieldId);
    if (!element || element.disabled || element.readOnly || myLockedField === fieldId) return;

    setMyLockedField(fieldId);
    fieldId = fieldId.toString();

    let [field, subSection] = fieldId.split(':');
    if (!field) return;
    const fieldDetails = field.split('-');
    ProjectSockets.emitLockField(
      fieldDetails[0],
      fieldDetails[1],
      fieldDetails[2],
      setMyLockedField
    );
  });

  const unlockFieldLogic = useCallback((fieldId) => {
    setMyLockedField(null);
    if (!fieldId) return;

    fieldId = fieldId.toString();
    let [field, subSection] = fieldId.split(':');
    if (!field) return;
    const fieldDetails = field.split('-');
    ProjectSockets.emitUnlockField(
      fieldDetails[0],
      fieldDetails[1],
      fieldDetails[2],
      setMyLockedField
    );
  });

  useEffect(() => {
    setLockField(() => lockFieldLogic);
    setUnlockField(() => unlockFieldLogic);
  }, []);

  const traverseChildElements = (element) => {
    if (!element) return;

    if (['INPUT', 'TEXTAREA', 'SELECT'].includes(element.tagName)) {
      const handleFocus = (event) => {
        const fieldId = event.target.getAttribute('name') || event.target.getAttribute('id');
        lockFieldLogic(fieldId);
      };

      const handleBlur = (event) => {
        const fieldId = event.target.getAttribute('name') || event.target.getAttribute('id');
        unlockFieldLogic(fieldId);
      };
      if (!element.dataset.listenersAdded) {
        element.addEventListener('focus', handleFocus);
        element.addEventListener('blur', handleBlur);
        element.dataset.listenersAdded = true;
      }
    }

    // Recursively traverse child elements
    element.childNodes.forEach((child) => traverseChildElements(child));
  };

  useEffect(() => {
    if (view.isLoading) return;
    const rootElement = componentRef.current;
    if (rootElement) {
      setTimeout(() => traverseChildElements(rootElement), 1000);
    }
  }, [
    view.sceneIndex,
    view.onScreen,
    view.isLoading,
    lockField,
    unlockField,
    view.viewColumns,
    isMobile,
  ]);

  return (
    <div>
      {!view.isLoading && !error && (
        <div>
          <ShotTableHeader view={view} setView={setView} />
        </div>
      )}

      <div>
        {view.isLoading && !error && (
          <div className="loadingMessage" style={{ padding: '30px' }}>
            Bear with us while we organize your scenes. This could take a minute or two. We promise
            it is worth the wait!
            <br />
            <img
              src="/Spinner-2.9s-297px.gif"
              style={{ height: '200px', width: '200px' }}
              className="smallLoadingGif"
              alt="Loading.."
            />
          </div>
        )}
        {error && (
          <div className="mainBox sizeUp errorMessage">
            <h3>Well then. This is embarrassing. We don’t know what happened here.</h3>
            <br />
            Try hitting refresh on your browser, if that does not work, it is likely that we are
            unable to find scenes within the file you uploaded. Please try again and ensure the
            script meets the following critieria:
            <ul>
              <li>
                Please ensure your script has slug lines for each scene such as "INT. LOCATION -
                NIGHT"
              </li>
              <li>
                Scripts do not necessarily need to be in English each scene must begin with INT,
                EXT, OMITTED, or SCENE.{' '}
              </li>
            </ul>
            <br />
            <br />
            If your script meets this criteria, please{' '}
            <a href="https://www.shotkraft.com/help" target="_blank">
              contact us
            </a>{' '}
            in this form so we can assist you. We are sorry for the inconvenience.
          </div>
        )}
        {!view.isLoading && !error && (
          <div className="FullWidthFlex" ref={componentRef}>
            <div className={`${view.viewColumns == 2 ? 'OneHalfWidth' : 'FullWidth'} `}>
              {!isMobile ? (
                <SceneTable view={view} setView={setView} />
              ) : (
                <>
                  <div className={`table-container ${sceneClass}`}>
                    <SceneTable view={view} setView={setView} />
                  </div>
                  <div className={`table-container ${shotClass}`}>
                    <ShotTable view={view} setView={setView} />
                  </div>
                </>
              )}
            </div>

            {view.viewColumns == 2 && !isMobile && (
              <div className="OneHalfWidth">
                <ShotTable view={view} setView={setView} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ShotList;
