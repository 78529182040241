import React, { useState, useEffect } from 'react';

function Checkbox({ name, label, selected, saveUpdate, spanClass, readOnly }) {
  const [isChecked, setIsChecked] = useState(selected);

  const handleInputChange = () => {
    if (readOnly) return;
    const newValue = !isChecked;
    setIsChecked(newValue);
    saveUpdate(null, name, newValue);
  };

  useEffect(() => {
    setIsChecked(selected);
  }, [selected]);

  return (
    <span className={spanClass}>
      <span className="label">{label}</span>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleInputChange}
        style={{ width: 'unset' }}
        disabled={readOnly}
      />
    </span>
  );
}

export default Checkbox;
