// Import Libraries
import React, { useState } from 'react';

// Import Services
import utilities from '../../../services/utilities';
import scriptService from '../../../services/scriptService';

// Construct Component
const CollaboratorLine = ({ script, updateScript, collaborator, level }) => {
  const updateAccess = async (newAccess) => {
    let updatedCollabList = await scriptService.editCollaborator(
      script.id,
      collaborator.id,
      newAccess
    );
    script.collaborators = updatedCollabList;
    updateScript(script);
  };

  return (
    <>
      <div
        className="fullWidthContainer"
        style={{
          padding: '5px',
          marginTop: '10px',
        }}
      >
        <span className="TenPortionFlex" style={{ textAlign: 'left', lineHeight: '2' }}>
          <span
            className="circleIcon userCircle"
            style={{
              marginRight: '5px',
              backgroundColor: `${collaborator?.color || '#fff'}`,
              color: utilities.isDarkColor(collaborator?.color) ? 'white' : 'black',
            }}
          >
            {collaborator?.initials}
          </span>
          {collaborator.email}
        </span>
        {level == 'owner' ? (
          <span className="TwoPortionFlex" style={{ textAlign: 'right' }}>
            Owner
          </span>
        ) : (
          <span className="TwoPortionFlex">
            <select
              value={level}
              onChange={(e) => updateAccess(e.target.value)}
              style={{
                padding: '5px 5px 5px 0px',
                border: 'none',
                outline: 'none',
                cursor: 'pointer',
                float: 'right',
                fontSize: '14px',
                fontFamily: 'Lato',
              }}
            >
              <option value="viewer">Viewer</option>
              <option value="editor">Editor</option>
              <option value="removeAccess">Remove</option>
            </select>
          </span>
        )}
      </div>
    </>
  );
};

export default CollaboratorLine;
