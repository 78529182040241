import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';

const LogoutButton = ({ useClass }) => {
  const { logout } = useAuth0();

  const handleSubmit = async (event) => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');

    const site = window.location.origin;
    if (site.indexOf('demo') === -1 && site.indexOf('localhost') === -1) {
      logout({ logoutParams: { returnTo: 'https://www.shotkraft.com' } });
    } else {
      logout({ logoutParams: { returnTo: window.location.origin } });
    }
  };

  return (
    <a className={`${useClass}`} onClick={() => handleSubmit()}>
      Log Out
    </a>
  );
};

export default LogoutButton;
