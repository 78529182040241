import React from 'react';

function ExternalPage({ page }) {
  return (
    <div className="containedPage">
      <iframe
        title="Embedded Webpage"
        src={page}
        width="100%"
        height="100%"
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default ExternalPage;
