function buildParams(method, body) {
  let header = {};
  if (body) {
    header = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    };
    if (body instanceof FormData) {
      //
    } else {
      header['Content-Type'] = 'application/json';
    }
    return { method: method, headers: header, body: body };
  } else {
    header = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    return { method: method, headers: header };
  }
}

const utils = {
  params: function (method, body) {
    let param = buildParams(method, body);
    return param;
  },

  buildTempShot: function (script, scene) {
    let tempShot = null;
    if (!scene.shot_list && (!script.features || script.features.toLowerCase() === 'basic')) {
      tempShot = [
        {
          id: 0,
          scene_id: scene.id,
          shot_number: 1,
          original_shot_number: 1,
          shot_type1: null,
          selected_camera: 'A',
          VFX: 0,
          FX: 0,
          shot_description: '',
          notes: '',
          movement: [],
          camera: [],
          focus: [],
          frame: [],
          focal: [],
          subject: [],
          expanded: false,
        },
      ];
    } else if (scene.shot_list) {
      return scene.shot_list;
    }
    return tempShot;
  },

  isDarkColor: function (color) {
    if (!color) {
      return false;
    }

    let r, g, b;
    if (color.length === 7) {
      r = parseInt(color.slice(1, 3), 16);
      g = parseInt(color.slice(3, 5), 16);
      b = parseInt(color.slice(5, 7), 16);
    } else {
      return true;
    }

    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    return luminance < 128;
  },
};

export default utils;
