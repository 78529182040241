// Import Libraries
import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { gtag } from 'ga-gtag';
import ReactGA from 'react-ga4';

// Import Components
import SelectPlan from '../Fragments/Subscribe/SelectPlan';
import ConfirmCancelSubscription from '../Fragments/Subscribe/confirmCancel';
import ProcessSubCharge from '../Fragments/Payments/ProcessSubCharge.js';
import Confirmation from '../Fragments/Payments/Confirmation';

// Import Services
import paymentService from '../../services/paymentService';

// Context
import { UserContext } from '../../context/User';

// Setup Modal to Root
Modal.setAppElement('#root');

const UpgradeMembership = ({
  featureMsg,
  isOpen,
  onRequestClose,
  includeFree,
  userDetails,
  currentSubscription,
  scriptId,
}) => {
  const { user, setUpdateUser } = useContext(UserContext);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [step, setStep] = useState(1);
  const site = window.location.origin;

  let useUser = userDetails ? userDetails : user;

  let dueDate = new Date();
  dueDate.setMonth(dueDate.getMonth() + 1);

  const moveToStep = async (step, plan) => {
    if (plan) {
      setSelectedPlan(plan);
    }

    //if (site.indexOf('demo') === -1 && site.indexOf('localhost') === -1) {

    if (step === 1) {
      if (!plan || !plan.details || !plan.details.id) return;
      gtag('event', 'begin_checkout', {
        currency: 'USD',
        value: plan.details.price,
        items: [
          {
            id: plan.details.id,
            name: plan.details.name,
            price: plan.details.price,
          },
        ],
        user_id: useUser.id,
      });
    } else if (step === 2) {
      if (!plan || !plan.details || !plan.details.id) return;
      gtag('event', 'add_payment_info', {
        currency: 'USD',
        value: plan.details.price,
        items: [
          {
            id: plan.details.id,
            name: plan.details.name,
            price: plan.details.price,
          },
        ],
        user_id: useUser.id,
      });
    } else if (step === 4) {
      setUpdateUser('subscription');
      gtag('event', 'purchase', {
        transaction_id: `txn_${Date.now()}`,
        value: selectedPlan.details.price,
        currency: 'USD',
        items: [
          {
            id: selectedPlan.details.id,
            name: selectedPlan.details.name,
            price: selectedPlan.details.price,
          },
        ],
        user_id: useUser.id,
      });
      //console.log('C moveToStep', step, plan);
      //openConfirmModal();
    }
    //}
    setStep(step);
    if (step === 5) {
      window.location.reload();
    }
  };

  const onClose = () => {
    if (step === 4) {
      window.location.reload();
    } else {
      onRequestClose(false);
    }
  };

  useEffect(() => {
    //gtag.event('view_item');
    setStep(1);
  }, [isOpen]);

  useEffect(() => {
    //console.log('useUser', useUser);
    const setupUseUser = async () => {
      //console.log('useUser 2: ', useUser);
      useUser.cards = await paymentService.getAvailableCards(useUser.id);
      useUser.invoiceDue = await paymentService.checkForPaymentsDue(useUser.id);
      //console.log('useUser 3: ', useUser);
    };
    setupUseUser();
  }, [useUser]);

  return (
    <>
      <Modal isOpen={isOpen} contentLabel="Idle Modal" className="modal wide">
        <div className="close-button" role="button" onClick={() => onClose()}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <div>
          {step === 1 ? (
            <SelectPlan
              featureMsg={featureMsg}
              includeFree={includeFree}
              nextStep={moveToStep}
              currentSubscription={currentSubscription}
              scriptId={scriptId}
            />
          ) : step === 2 ? (
            <ProcessSubCharge
              plan={selectedPlan}
              onRequestClose={onRequestClose}
              scriptId={scriptId}
              nextStep={moveToStep}
              userDetails={useUser}
            />
          ) : step === 3 ? (
            <ConfirmCancelSubscription
              currentSubscription={currentSubscription}
              onRequestClose={onRequestClose}
            />
          ) : step === 4 ? (
            <Confirmation scriptId={scriptId} nextStep={moveToStep} />
          ) : null}
        </div>
        {step < 4 ? (
          <div className="FullWidthFlex" style={{ paddingTop: '30px', marginBottom: '-20px' }}>
            <span className="FivePortionFlex">&nbsp;</span>
            <span
              className="OnePortionFlex buttonClone"
              style={{ height: '5px', backgroundColor: step === 1 ? '#a899a8' : '' }}
              onClick={() => moveToStep(1)}
            ></span>
            <span
              className="OnePortionFlex buttonClone"
              style={{ height: '5px', backgroundColor: step === 2 ? '#a899a8' : '' }}
            ></span>
            <span className="FivePortionFlex">&nbsp;</span>
          </div>
        ) : null}
      </Modal>
    </>
  );
};

export default UpgradeMembership;
