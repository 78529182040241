// Libraries
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import reportWebVitals from './reportWebVitals';

// Styles
import './styles/baseElements.css';

// Components
import App from './App';

// Authentication Configuration
const providerConfig = {
  domain: process.env.REACT_APP_AUTH_DOMAIN,
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
  authorizationParams: {
    audience: process.env.REACT_APP_AUTH_AUDIENCE,
    redirect_uri: `${window.location.origin}/app/home`,
  },
};

// Render App
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider {...providerConfig}>
    <App />
  </Auth0Provider>
);

// TO DO: Add Web Reporting
reportWebVitals();
