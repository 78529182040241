import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faClose } from '@fortawesome/free-solid-svg-icons';

const CustomSelect = ({
  optionList = [],
  selected = [],
  baseItem,
  selectedFieldName,
  changeHandler,
  addOption,
  label,
  id,
  readOnly,
  multi,
  lockField,
  unlockField,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const selectRef = useRef(null);
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const focusedInputRef = useRef(null);
  const globalFocusRef = useRef(null);
  const [focused, setFocused] = useState(false);
  const fieldId = id || 'customSelect';

  const handleSelect = (value) => {
    let updatedValues;
    if (multi) {
      const alreadySelected = selected.includes(value);

      if (alreadySelected) {
        updatedValues = selected.filter((v) => v !== value);
      } else {
        updatedValues = [...selected, value];
      }
    } else {
      updatedValues = value;
    }

    if (changeHandler) {
      changeHandler(baseItem.id, selectedFieldName, updatedValues);
    }

    toggleDropdown(false);
  };

  // Handle adding a new option and selecting it
  const handleAddOption = async (value) => {
    if (value.trim() === '') return;
    let newOption = { value: value, label: value };
    if (!optionList.some((option) => option.value === value)) {
      let updated = await addOption(newOption, selectedFieldName);
      newOption = updated ? updated : newOption;
    }
    handleSelect(newOption.value);
    setInputValue('');
  };

  // Handle adding a new option when Enter is pressed
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && inputValue.trim() !== '') {
      handleAddOption(inputValue);
      e.preventDefault();
    }
  };

  const toggleDropdown = (newValue) => {
    if (document.activeElement && document.activeElement !== inputRef.current) {
      //document.activeElement.blur();
    }

    let currentDropdown = isDropdownOpen;
    setIsDropdownOpen(newValue);

    if (newValue && lockField) {
      lockField(fieldId);
    } else if (!newValue && unlockField && currentDropdown) {
      unlockField(fieldId);
    }

    // Ensure the focused state is reset for any other open dropdowns
    if (globalFocusRef.current && globalFocusRef.current !== inputRef.current) {
      globalFocusRef.current.blur();
      setFocused(false);
    }
  };

  const getSelectedLabels = () => {
    return selected.map((val) => {
      const option = optionList.find((opt) => opt.value === val);
      return option ? option.label : val;
    });
  };

  const handleContainerClick = (e) => {
    if (focusedInputRef.current && focusedInputRef.current !== inputRef.current) {
      focusedInputRef.current.blur();
    }

    if (inputRef.current) {
      inputRef.current.focus();
      focusedInputRef.current = inputRef.current;
    }
  };

  const handleStopPropagation = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        toggleDropdown(false);
      }
    };

    const handleFocusOutside = (e) => {
      if (isDropdownOpen && containerRef.current && !containerRef.current.contains(e.target)) {
        //toggleDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('focusin', handleFocusOutside); // Listen for focus changes

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('focusin', handleFocusOutside);
    };
  }, [isDropdownOpen]);

  return (
    <div
      className={`createableMultiContainer createableFocusLabelField ${
        isDropdownOpen && !readOnly ? '' : 'createableFocusLabelFieldVisible'
      } fullWidthContainer`}
      id={fieldId}
      onClick={(e) => {
        if (!readOnly) {
          handleContainerClick();
        }
      }}
      ref={containerRef}
    >
      <div className="fullWidthContainer" style={{ alignItems: 'stretch', flexWrap: 'nowrap' }}>
        <span
          className={`createableFocusLabel ${readOnly ? 'readOnly' : ''} ${
            isDropdownOpen && !readOnly ? '' : 'visibleLabel'
          }`}
        >
          <span className="createableFocusLabelText">{label}</span>
        </span>
        <div
          className={`createableMulti withFormMargin fullWidthContainer`}
          onClick={(e) => {
            if (!readOnly) {
              toggleDropdown(!isDropdownOpen);
            }
          }}
          id={`${fieldId}:multi`}
        >
          <div
            className={`createableMulti__control ${readOnly ? 'readOnly' : ''}`}
            id={`${fieldId}:control`}
          >
            <div
              className="createableMulti__value-container TenPortionFlex"
              id={`${fieldId}:value-container`}
            >
              <div className="fullWidthContainer">
                {getSelectedLabels().map((label, index) => (
                  <div key={index} className="createableMulti__multi-value OnePortionFlex">
                    <div className="createableMulti__multi-value__label FivePortionFlex">
                      {label}
                    </div>

                    {multi && (
                      <div
                        className={`createableMulti__multi-value__remove OnePortionFlex ${
                          readOnly ? 'readOnly' : ''
                        }`}
                        onClick={(e) => {
                          if (!readOnly) {
                            handleSelect(selected[index]);
                            handleStopPropagation(e);
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={faClose} />
                      </div>
                    )}
                  </div>
                ))}
                <span className="createableMulti__input-container OnePortionFlex">
                  <input
                    ref={inputRef}
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder={selected.length === 0 ? 'Select' : ''}
                    className="createableMulti__input"
                    size={Math.max(inputValue.length, 5)}
                    style={{
                      all: 'unset',
                      display: addOption ? 'block' : 'none',
                    }}
                    id={`${fieldId}:input`}
                    onMouseDown={(e) => {
                      toggleDropdown(!isDropdownOpen);
                      handleStopPropagation(e);
                    }}
                    disabled={!addOption || readOnly}
                  />
                </span>
              </div>
            </div>
            <span
              className="OnePortionFlex"
              onClick={(e) => {
                if (!readOnly) {
                  toggleDropdown(!isDropdownOpen);
                  handleStopPropagation(e);
                }
              }}
            >
              <FontAwesomeIcon className="dropdownIcon" icon={faCaretDown} />
            </span>

            {isDropdownOpen && (
              <div className="fullWidthContainer">
                <div className="createableMulti__menu">
                  <ul className="createableMulti__menu-list">
                    {optionList.map((option) => (
                      <li
                        key={option.value}
                        className={`createableMulti__option ${
                          selected.includes(option.value) ? 'selectedOption' : ''
                        }`}
                        onClick={(e) => {
                          if (!readOnly) {
                            handleSelect(option.value);
                          }
                        }}
                      >
                        {option.label}
                        {selected.includes(option.value) && <span className="checkMark">✔</span>}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomSelect;
