import React, { createContext, useState, useMemo, useEffect } from 'react';

// Import Services
import userService from '../services/userService.js';
import { useAuth0 } from '@auth0/auth0-react';

// Create the UserContext
export const UserContext = createContext({});

// Create the Provider Component
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : {};
  });
  const [updateUser, setUpdateUser] = useState('');
  const { isAuthenticated, isLoading, getAccessTokenSilently, logout } = useAuth0();

  const setTokens = async () => {
    const accessToken = await getAccessTokenSilently();
    localStorage.setItem('token', accessToken);
    return accessToken;
  };

  useEffect(() => {
    if (user?.id) {
      localStorage.setItem('user', JSON.stringify(user));
    }
  }, [user]);

  useEffect(() => {
    const verifyAccessToken = async () => {
      if (isLoading) return;
      if (!isAuthenticated && !localStorage.getItem('token') && user.id) {
        console.log('Removing user due to non-authentication');
        localStorage.removeItem('user');
      }
    };
    verifyAccessToken();
  }, [isAuthenticated, isLoading]);

  const contextValue = useMemo(
    () => ({ user, setUser, updateUser, setUpdateUser }),
    [user, updateUser]
  );

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

export default UserContext;
